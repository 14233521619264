import { gql } from "@apollo/client";

export const UPLOAD_PREVIRED = gql`
  mutation uploadPreviredFile($input: uploadPreviredFileInputData) {
    period {
      collection {
        previred {
          uploadFile(input: $input) {
            result
            message
            data {
              id
              name
              size
              type
              mimeType
              targetMonth
              targetYear
              bucketId
            }
          }
        }
      }
    }
  }
`;

export const CLOSE_PERIOD = gql`
  mutation ClosePeriod($input: closePeriodInputType) {
    previred {
      closePeriod(input: $input) {
        status
      }
    }
  }
`;

export const RUN_ETL = gql`
  mutation Run($input: previredETLMutationInput) {
    previred {
      etl {
        run(input: $input) {
          message
          result
        }
      }
    }
  }
`;

export const CHECK_STATUS = gql`
  mutation CheckStatus($input: previredETLMutationInput) {
    previred {
      etl {
        checkStatus(input: $input) {
          result
          message
          data {
            status
            statusCheckETL
          }
        }
      }
    }
  }
`;

export const DOWNLOAD_PREVIRED_FILE = gql`
  mutation Download($input: previredETLMutationInput) {
    previred {
      etl {
        download(input: $input) {
          data {
            etlJson
            status
          }
          result
          message
        }
      }
    }
  }
`;

export const CHECK_INVENTORY_STATUS = gql`
  mutation CheckStatus($input: inventoryETLMutationInput) {
    inventory {
      etl {
        checkStatus(input: $input) {
          result
          message
          data {
            status
            statusCheckETL
            codeFalse
          }
        }
      }
    }
  }
`;

export const INSERT_CME = gql`
  mutation InsertCME($input: inventoryETLMutationInput) {
    inventory {
      etl {
        insertCME(input: $input) {
          result
          message
          data {
            state
            status
          }
        }
      }
    }
  }
`;

export const RUN_INVENTORY_ETL = gql`
  mutation Run($input: inventoryETLMutationInput) {
    inventory {
      etl {
        run(input: $input) {
          data {
            etlJobId
            status
          }
          message
          result
        }
      }
    }
  }
`;

export const UPDATE_SEND_CME = gql`
  mutation Mutation($input: inventoryUpdateSendCMEMutationInput) {
    inventory {
      dateSend(input: $input) {
        result
        message
        data {
          result
        }
      }
    }
  }
`;

export const UPLOAD_CONFIRMED_INVENTORY = gql`
  mutation File($input: inventoryInput) {
    inventory {
      file(input: $input) {
        result
        message
        data {
          name
          size
          mimeType
          state
        }
      }
    }
  }
`;

export const PAYMENT_DATE_CONFIRMED = gql`
  mutation Payment($input: inventoryPaymentInput) {
    inventory {
      payment(input: $input) {
        result
        message
        data {
          date
          payments
          state
        }
      }
    }
  }
`;

export const TERMINATE_PROCESS = gql`
  mutation FinalProcess($input: inventoryProcessFinalInputType) {
    inventory {
      finalProcess(input: $input) {
        result
        message
        data {
          documentId
          status
        }
      }
    }
  }
`;

export const HANDOVER_DATE = gql`
  mutation ThroughPut($input: throughPutInputType) {
    inventory {
      throughPut(input: $input) {
        result
        message
        data {
          inventoryId
          institutionId
          cmeThroughPutDate
        }
      }
    }
  }
`;

export const INSERT_JSON_CONTRACT = gql`
  mutation bulkCreateContracts($input: bulkCreateInputType!) {
    period {
      collection {
        contributorContract {
          bulkCreate(input: $input) {
            result
            message
            data {
              logId
            }
          }
        }
      }
    }
  }
`;

export const INSERT_FILE_CONTRACT = gql`
  mutation uploadContributorContractExcel($input: uploadExcelInputType!) {
    period {
      collection {
        contributorContract {
          uploadExcel(input: $input) {
            result
            message
            data {
              logId
            }
          }
        }
      }
    }
  }
`;

export const INSERT_JSON_PAYMENT = gql`
  mutation bulkCreatePayments($input: bulkCreateInputType!) {
    period {
      collection {
        payment {
          bulkCreate(input: $input) {
            result
            message
            data {
              logId
            }
          }
        }
      }
    }
  }
`;

export const INSERT_JSON_SURPLUS = gql`
  mutation bulkCreateSurplus($input: bulkCreateInputType!) {
    period {
      distribution {
        surplus {
          bulkCreate(input: $input) {
            result
            message
            data {
              logId
            }
          }
        }
      }
    }
  }
`;

export const INSERT_JSON_EXCESS = gql`
  mutation bulkCreateExcess($input: bulkCreateInputType!) {
    period {
      distribution {
        excess {
          bulkCreate(input: $input) {
            result
            message
            data {
              logId
            }
          }
        }
      }
    }
  }
`;

export const INSERT_JSON_DEVOLUTION = gql`
  mutation bulkCreateDevolutionMovements($input: bulkCreateInputType!) {
    period {
      distribution {
        devolution {
          devolutionMovement {
            bulkCreate(input: $input) {
              result
              message
              data {
                logId
              }
            }
          }
        }
      }
    }
  }
`;

export const INSERT_FILE_PAYMENT = gql`
  mutation uploadPaymentExcel($input: uploadExcelInputType!) {
    period {
      collection {
        payment {
          uploadExcel(input: $input) {
            result
            message
            data {
              logId
            }
          }
        }
      }
    }
  }
`;

export const INSERT_FILE_SURPLUS = gql`
  mutation uploadSurplusExcel($input: uploadExcelInputType!) {
    period {
      distribution {
        surplus {
          uploadExcel(input: $input) {
            result
            message
            data {
              logId
            }
          }
        }
      }
    }
  }
`;

export const INSERT_FILE_EXCESS = gql`
  mutation uploadExcessExcel($input: uploadExcelInputType!) {
    period {
      distribution {
        excess {
          uploadExcel(input: $input) {
            result
            message
            data {
              logId
            }
          }
        }
      }
    }
  }
`;

export const INSERT_FILE_DEVOLUTION = gql`
  mutation uploadDevolutionMovementsExcel($input: uploadExcelInputType!) {
    period {
      distribution {
        devolution {
          devolutionMovement {
            uploadExcel(input: $input) {
              result
              message
              data {
                logId
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_CONTRACT = gql`
  mutation updateContributorContract(
    $input: updateContributorContractInputType!
  ) {
    period {
      collection {
        contributorContract {
          update(input: $input) {
            result
            message
          }
        }
      }
    }
  }
`;

export const DELETE_CONTRACT = gql`
  mutation deleteContributorContract(
    $input: deleteContributorContractInputType!
  ) {
    period {
      collection {
        contributorContract {
          delete(input: $input) {
            result
            message
          }
        }
      }
    }
  }
`;

export const DELETE_PAYMENT = gql`
  mutation deletePayment($input: deletePaymentInputType!) {
    period {
      collection {
        payment {
          delete(input: $input) {
            result
            message
          }
        }
      }
    }
  }
`;

export const UPDATE_PAYMENT = gql`
  mutation updatePayment($input: updatePaymentInputType!) {
    period {
      collection {
        payment {
          update(input: $input) {
            result
            message
          }
        }
      }
    }
  }
`;

export const BULK_UPDATE_PAYMENT_STATUS = gql`
  mutation bulkUpdatePayment($input: bulkUpdatePaymentInputType!) {
    period {
      collection {
        payment {
          bulkUpdate(input: $input) {
            result
            message
          }
        }
      }
    }
  }
`;

export const SPLIT_PAYMENT = gql`
  mutation splitPayment($input: splitPaymentInputType!) {
    period {
      collection {
        payment {
          split(input: $input) {
            result
            message
          }
        }
      }
    }
  }
`;

export const SPLIT_PREVIRED = gql`
  mutation splitPreviredPayment($input: splitPaymentInputType!) {
    period {
      collection {
        payment {
          splitPrevired(input: $input) {
            result
            message
            data {
              paymentsData {
                id
                amount
              }
            }
          }
        }
      }
    }
  }
`;

export const CLOSE_COLLECTION_PERIOD = gql`
  mutation closeCollectionProcess($input: closeProcessInputType!) {
    period {
      closeCollectionProcess(input: $input) {
        result
        message
      }
    }
  }
`;

export const CLOSE_DISTRIBUTION_PROCESS = gql`
  mutation closeDistributionProcess($input: closeProcessInputType!) {
    period {
      closeDistributionProcess(input: $input) {
        result
        message
      }
    }
  }
`;

export const CLOSE_DEVOLUTION_PROCESS = gql`
  mutation closeDevolutionProcess($input: closeProcessInputType!) {
    period {
      closeDevolutionProcess(input: $input) {
        result
        message
      }
    }
  }
`;

export const UPDATE_EXCESS = gql`
  mutation updateExcess($input: updateExcessInputType!) {
    period {
      distribution {
        excess {
          updateExcess(input: $input) {
            result
            message
            data {
              id
              excessGenerated
              readjustment
              interest
              commission
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_SURPLUS = gql`
  mutation updateSurplus($input: updateSurplusInputType!) {
    period {
      distribution {
        surplus {
          update(input: $input) {
            result
            message
          }
        }
      }
    }
  }
`;

export const UPDATE_SURPLUS_MOVEMENT = gql`
  mutation updateSurplusMovement($input: updateSurplusMovementInputType!) {
    period {
      distribution {
        surplus {
          surplusMovement {
            update(input: $input) {
              result
              message
              data {
                id
                amount
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_SURPLUS = gql`
  mutation deleteSurplus($input: deleteSurplusInputType!) {
    period {
      distribution {
        surplus {
          delete(input: $input) {
            result
            message
          }
        }
      }
    }
  }
`;

export const CREATE_UF = gql`
  mutation createCurrency($input: createCurrencyInputType!) {
    period {
      currency {
        create(input: $input) {
          result
          message
        }
      }
    }
  }
`;

export const UPDATE_UF = gql`
  mutation updateCurrency($input: updateCurrencyInputType!) {
    period {
      currency {
        update(input: $input) {
          result
          message
        }
      }
    }
  }
`;

export const CREATE_SURPLUS_MOVEMENT = gql`
  mutation createSurplusMovement($input: createSurplusMovementInputType!) {
    period {
      distribution {
        surplus {
          surplusMovement {
            create(input: $input) {
              result
              message
              data {
                id
                contributorRut
                paymentPeriod
                collectionPeriod
                availableDate
                movementDate
                movementType
                amount
                amountType
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_EXCESS_MOVEMENT = gql`
  mutation updateExcessMovement($input: updateExcessMovementInputType!) {
    period {
      distribution {
        excess {
          movement {
            update(input: $input) {
              result
              message
              data {
                id
                amount
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_SURPLUS_MOVEMENT = gql`
  mutation deleteSurplusMovement($input: deleteSurplusMovementInputType!) {
    period {
      distribution {
        surplus {
          surplusMovement {
            delete(input: $input) {
              result
              message
            }
          }
        }
      }
    }
  }
`;

export const BULK_UPDATE_SURPLUS_MOVEMENT = gql`
  mutation bulkUpdateSurplusMovement(
    $input: bulkUpdateSurplusMovementsInputType!
  ) {
    period {
      distribution {
        surplus {
          surplusMovement {
            bulkUpdate(input: $input) {
              result
              message
            }
          }
        }
      }
    }
  }
`;

export const REVERSE_SURPLUS_MOVEMENT = gql`
  mutation reverseSurplusMovement($input: reverseSurplusMovementInputType!) {
    period {
      distribution {
        surplus {
          surplusMovement {
            reverse(input: $input) {
              result
              message
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_SAP = gql`
  mutation updateSAPClassification(
    $input: updateExcessSAPClassificationInputType!
  ) {
    period {
      distribution {
        excess {
          updateSAPClassification(input: $input) {
            result
            message
            data {
              collectionPeriod
              statusName
            }
          }
        }
      }
    }
  }
`;

export const DELETE_EXCESS = gql`
  mutation deleteExcess($input: deleteExcessInputType!) {
    period {
      distribution {
        excess {
          delete(input: $input) {
            result
            message
          }
        }
      }
    }
  }
`;

export const BULK_UPDATE_EXCESS_MOVEMENT = gql`
  mutation bulkUpdateExcessMovements(
    $input: bulkUpdateExcessMovementsInputType!
  ) {
    period {
      distribution {
        excess {
          excessMovement {
            bulkUpdate(input: $input) {
              result
              message
              data {
                movementsData {
                  id
                  updated
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_EXCESS_MOVEMENT = gql`
  mutation deleteExcessMovement($input: deleteExcessMovementInputType!) {
    period {
      distribution {
        excess {
          excessMovement {
            delete(input: $input) {
              result
              message
            }
          }
        }
      }
    }
  }
`;

export const CREATE_EXCESS_MOVEMENT = gql`
  mutation createExcessMovement($input: createExcessMovementInputType!) {
    period {
      distribution {
        excess {
          excessMovement {
            create(input: $input) {
              result
              message
            }
          }
        }
      }
    }
  }
`;

export const PROCESS_DEVOLUTION = gql`
  mutation processExcessDevolution($input: processExcessDevolutionInputType!) {
    period {
      distribution {
        excess {
          processDevolution(input: $input) {
            result
            message
            data {
              totalRowsUpdated
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_EXCESS_CLASSIFICATION_DEV = gql`
  mutation updateExcessClassification(
    $input: updateExcessClassificationInputType!
  ) {
    period {
      distribution {
        excess {
          updateClassification(input: $input) {
            result
            message
            data {
              id
              typeName
            }
          }
        }
      }
    }
  }
`;

export const LOG_OUT = gql`
  mutation Utils {
    utils {
      disableToken
    }
  }
`;
