import { useReducer } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import icon_warningOrange from "../../../../assets/icons/icon_warningOrange.svg";
import { AlertSnackbar, CustomModal, CustomModalTitle } from "src/components";
import { modalTitle } from "src/components/typography/styles";
import { getCurrentPeriod } from "src/utils/dates";
import {
  DELETE_CONTRACT,
  DELETE_PAYMENT,
  DELETE_SURPLUS,
  DELETE_SURPLUS_MOVEMENT,
} from "src/services/graphql/mutations";
import { useMutation } from "@apollo/client";
import constants from "src/constants/constants";
import { capitalCase } from "src/utils/stringUtils";
import {
  SNACK_ACTION_TYPE,
  SNACKINFO_INITIAL_STATE,
  snackInfoReducer,
} from "src/reducers/snackInfoReducer";

const {
  CLOSE_SNACK_INFO,
  SET_SNACK_ERROR: ERROR,
  SET_SNACK_SUCCESS: SUCCESS,
} = SNACK_ACTION_TYPE;
const {
  COMPONENTS: {
    BUTTONS: { DELETE, CANCEL },
  },
  QUERIES: {
    CODE: { VALIDATION_FAILED },
    FETCH_POLICY: { NETWORK_ONLY },
  },
  SNACKBAR_MESSAGE: {
    CREDITS_DELETE_ERROR,
    CREDITS_DELETE_SUCCESS,
    DISTRIBUTION: { EXCESS_DELETE_ERROR, EXCESS_DELETE_SUCCESS },
    EXCESS_MOVEMENT,
    PAYMENTS_DELETE_ERROR,
    PAYMENTS_DELETE_SUCCESS,
  },
} = constants;

export const DeleteModal = ({
  onOpen,
  onClose,
  id,
  rut,
  payerRut,
  period,
  setRefresh,
  setSnackInfoParent,
  refreshOnError,
  type,
  info,
  handleEditClose,
}: any) => {
  const [snackInfoState, snackInfoDispatch] = useReducer(
    snackInfoReducer,
    SNACKINFO_INITIAL_STATE
  );

  const [deleteCredit, { loading }] = useMutation(DELETE_CONTRACT, {
    fetchPolicy: NETWORK_ONLY as any,
    variables: {
      input: {
        id: id,
      },
    },
    onCompleted: () => {
      handleAlert(CREDITS_DELETE_SUCCESS, SUCCESS);
    },
    onError: () => {
      handleAlert(CREDITS_DELETE_ERROR, ERROR);
    },
  });

  const [deletePayment, { loading: loadingPayment }] = useMutation(
    DELETE_PAYMENT,
    {
      fetchPolicy: NETWORK_ONLY as any,
      variables: {
        input: {
          id: parseInt(id),
        },
      },
      onCompleted: () => {
        handleAlert(PAYMENTS_DELETE_SUCCESS, SUCCESS);
      },
      onError: () => {
        handleAlert(PAYMENTS_DELETE_ERROR, ERROR);
      },
    }
  );

  const [deleteExcess, { loading: loadingExcess }] = useMutation(
    DELETE_SURPLUS,
    {
      fetchPolicy: NETWORK_ONLY as any,
      variables: {
        input: {
          id: parseInt(id),
        },
      },
      onCompleted: () => {
        handleAlert(EXCESS_DELETE_SUCCESS, SUCCESS);
      },
      onError: (error: any) => {
        let { message, code } = error?.graphQLErrors[0] || {};
        if (VALIDATION_FAILED === code) {
          snackInfoDispatch({
            type: ERROR,
            payload: message.split(".")[0],
          });
          let errorStatus = message.split(":")[1];
          refreshOnError(parseInt(errorStatus));
          onClose();
        } else {
          handleAlert(EXCESS_DELETE_ERROR, ERROR);
        }
      },
    }
  );

  const [deleteExcessMovement, { loading: loadingExcessMovement }] =
    useMutation(DELETE_SURPLUS_MOVEMENT, {
      fetchPolicy: NETWORK_ONLY as any,
      onCompleted: () => {
        setRefresh(true);
        setSnackInfoParent({
          type: SUCCESS,
          payload: EXCESS_MOVEMENT.DELETE_SUCCESS,
        });
        onClose();
      },
      onError: (error: any) => {
        let { message, code } = error?.graphQLErrors[0] || {};
        if (VALIDATION_FAILED === code) {
          setSnackInfoParent({
            type: ERROR,
            payload: message.split(".")[0],
          });
          refreshOnError();
          handleEditClose();
        } else {
          setSnackInfoParent({
            type: ERROR,
            payload: EXCESS_MOVEMENT.DELETE_ERROR,
          });
        }
        onClose();
      },
    });

  const handleDelete = () => {
    switch (type) {
      case 1:
        deleteCredit();
        break;
      case 2:
        deletePayment();
        break;
      case 3:
        deleteExcess();
        break;
      case 4:
        deleteExcessMovement({
          variables: {
            input: {
              id: info.id,
            },
          },
        });
        break;
      default:
        break;
    }
  };

  const handleAlert = (message: string, status: string) => {
    if (status === SUCCESS) {
      setRefresh(true);
    }
    snackInfoDispatch({
      type: status,
      payload: message,
    });
    onClose();
  };

  return (
    <>
      <AlertSnackbar        
        onClose={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        open={snackInfoState.open}
        status={snackInfoState.status}
        message={snackInfoState.message}
      />
      <CustomModal
        fullWidth
        maxWidth="sm"
        open={onOpen}
        onClose={onClose}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <CustomModalTitle id="warning" onClose={onClose}>
          <Typography sx={modalTitle}>
            Eliminar {type === 4 && "movimiento"}
          </Typography>
        </CustomModalTitle>
        {type === 4 ? (
          <DialogContent
            dividers
            sx={{
              textAlign: "center",
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            <img
              alt="img"
              src={icon_warningOrange}
              style={{ marginTop: "3px" }}
            />
            <div style={{ textAlign: "center", paddingTop: "19px" }}>
              <label className="firstTextModal">
                Estás eliminando el movimiento:
              </label>{" "}
              <br></br>
              <label className="blueModalText">
                {capitalCase(info.amountType)}, {capitalCase(info.movementType)}
                , monto de ${info.amount}
              </label>
              <br></br>
              <label className="boldModalText">
                {" "}
                ¿Estás seguro de continuar con esta acción?
              </label>
            </div>
          </DialogContent>
        ) : (
          <DialogContent
            dividers
            sx={{
              textAlign: "center",
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            <img
              alt="img"
              src={icon_warningOrange}
              style={{ marginTop: "3px" }}
            />
            <div style={{ textAlign: "center", paddingTop: "19px" }}>
              <label className="firstTextModal">
                Al eliminar este registro no podrá recuperar los datos
                eliminados <br></br>
                correspondientes al
              </label>{" "}
              <br></br>
              <label className="blueModalText">
                Periodo {getCurrentPeriod(period)[0]} - RUT {rut}
                {payerRut !== null && (
                  <>
                    <br></br>
                    RUT Pagador {payerRut}
                  </>
                )}
              </label>
              <br></br>
              <br></br>
              <label className="boldModalText">
                {" "}
                ¿Estás seguro de continuar con esta acción?
              </label>
            </div>
          </DialogContent>
        )}

        <DialogActions sx={{ textAlign: "center" }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {loading ||
            loadingPayment ||
            loadingExcess ||
            loadingExcessMovement ? (
              <Button color="primary" sx={{ height: "50px", width: "107.4px" }}>
                <div className="spinnerButton"></div>
              </Button>
            ) : (
              <Button color="primary" onClick={() => handleDelete()}>
                {DELETE}
              </Button>
            )}
            <Button
              color="secondary"
              onClick={onClose}
              sx={{ marginLeft: "24px" }}
            >
              {CANCEL}
            </Button>
          </Grid>
        </DialogActions>
      </CustomModal>
    </>
  );
};
