import { useEffect, useReducer, useState } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import {
  subMenuBox,
  titleMenuBox,
  ufText,
  warningGray,
  warningGrayBold,
} from "src/components/typography/styles";
import { filterBox } from "src/components/box/styles";
import { AlertSnackbar, Breadcrum, WarningModalCustom } from "src/components";
import ExcedenteIcon from "../../assets/icons/exedentesIcon.svg";
import DevolucionIcon from "../../assets/icons/devolucionIcon.svg";
import FechaIcon from "../../assets/icons/dateIcon.svg";
import UploadUF from "./UF/uploadUF";
import HistoryUF from "./UF/historyUF";
import SkeletonBar from "src/components/skeleton/skeletonBar";
import constants from "src/constants/constants";
import { getCurrentPeriod } from "src/utils/dates";
import {
  SNACK_ACTION_TYPE,
  SNACKINFO_INITIAL_STATE,
  snackInfoReducer,
} from "src/reducers/snackInfoReducer";
import { useCurrency } from "src/hooks/useCurrency";

const { CLOSE_SNACK_INFO, SET_SNACK_ERROR, SET_SNACK_SUCCESS } =
  SNACK_ACTION_TYPE;
const {
  COMPONENTS: {
    BUTTONS: { HYSTORY, UPDATE },
  },
  SNACKBAR_MESSAGE: {
    SETTINGS: {
      CREATE_CURRENCY_ERROR,
      CREATE_CURRENCY_SUCCESS,
      UPDATE_CURRENCY_ERROR,
      UPDATE_CURRENCY_SUCCESS,
    },
  },
} = constants;

const SettingsMenu = () => {
  const [refresh, setRefresh] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [snackInfoState, snackInfoDispatch] = useReducer(
    snackInfoReducer,
    SNACKINFO_INITIAL_STATE
  );

  const [ufInfo, setUfInfo] = useState({
    period: "",
    date: "",
    value: 0,
    newValue: 0,
    displayValue: 0,
  });

  const {
    getCurrentCurrency,
    current,
    loadingCurrent,
    addCurrency,
    loadingAddCurrency,
    editCurrency,
    loadingEditCurrency,
  } = useCurrency();

  const createCurrency = () => {
    addCurrency({
      periodName: current.periodName,
      currencyType: 1,
      currencyValue: ufInfo.newValue,
      onCompleted: () => handleAlert(1),
      onError: () => handleAlertError(1),
    });
  };

  const updateCurrency = () => {
    editCurrency({
      id: current.currencyId,
      currencyValue: ufInfo.newValue,
      onCompleted: () => handleAlert(2),
      onError: () => handleAlertError(2),
    });
  };

  const handleSave = () => {
    current.currencyId === null ? createCurrency() : updateCurrency();
  };

  const handleAlert = (type: number) => {
    setRefresh(true);
    snackInfoDispatch({
      type: SET_SNACK_SUCCESS,
      payload: type === 1 ? CREATE_CURRENCY_SUCCESS : UPDATE_CURRENCY_SUCCESS,
    });
    setOpenWarning(false);
  };

  const handleAlertError = (type: number) => {
    snackInfoDispatch({
      type: SET_SNACK_ERROR,
      payload: type === 1 ? CREATE_CURRENCY_ERROR : UPDATE_CURRENCY_ERROR,
    });
    setOpenWarning(false);
  };

  const WarningModalContent = () => {
    return (
      <>
        <br></br>
        {current.currencyId ? (
          <Typography sx={warningGray}>
            ¿Quieres cambiar el valor de la UF para el periodo seleccionado?
            Esto implica que habrá que recalcular valores en CME
          </Typography>
        ) : (
          <>
            <Typography sx={warningGray}>¿Quieres confirmar que la </Typography>
            <Typography sx={warningGrayBold}>
              UF del día {ufInfo.date}{" "}
            </Typography>
            <Typography sx={warningGray}>corresponde a </Typography>
            <Typography sx={warningGrayBold}>{ufInfo.displayValue}?</Typography>
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    getCurrentCurrency();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refresh === true) {
      getCurrentCurrency();
      setRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    if (current) {
      setUfInfo({
        ...ufInfo,
        period: current.periodName,
        value: current.currencyValue ? current.currencyValue : 0,
        displayValue:
          current.currencyValue === null ? current.currencyValue : 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  return (
    <>
      <AlertSnackbar        
        onClose={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        open={snackInfoState.open}
        status={snackInfoState.status}
        message={snackInfoState.message}
      />
      <WarningModalCustom
        secondaryButton="Volver"
        primaryButton="Guardar"
        contentText={<WarningModalContent />}
        title="Confirmar valor"
        headWarning
        onOpen={openWarning}
        loading={loadingAddCurrency || loadingEditCurrency}
        loadingWidth={"105px"}
        onClose={() => {
          setOpenWarning(false);
          setOpenUpload(true);
        }}
        onConfirm={handleSave}
      />
      <HistoryUF onOpen={openHistory} onClose={() => setOpenHistory(false)} />
      <UploadUF
        onOpen={openUpload}
        onClose={() => setOpenUpload(false)}
        ufInfo={ufInfo}
        setUfInfo={setUfInfo}
        onConfirm={() => setOpenWarning(true)}
      />
      <Box pl="32px" pr="32px">
        <Typography variant="h1" pt="24px" mb="16px">
          Configuraciones
        </Typography>
        <Breadcrum back1="Inicio" link1="/" now="Configuraciones" />
        <Grid container pt="24px">
          <Grid xs={4} pr="24px" textAlign={"center"}>
            <Box sx={filterBox} pt="30px !important">
              <img src={ExcedenteIcon} alt="" />
              <Typography pt="16px" sx={titleMenuBox}>
                UF
              </Typography>
              {loadingCurrent ? (
                <Stack
                  direction={"column"}
                  spacing={"12px"}
                  alignItems={"center"}
                  pt="14px"
                  pb="28px"
                >
                  <SkeletonBar width={64} height={14} />
                  <SkeletonBar width={327} height={20} />
                </Stack>
              ) : (
                <>
                  <Typography pt="12px" sx={subMenuBox}>
                    {getCurrentPeriod(ufInfo.period)[0]?.split(" ")[0]}
                  </Typography>
                  <Typography pt="12px" pb="24px" sx={ufText}>
                    ${ufInfo.value.toLocaleString("es-cl")}
                  </Typography>
                </>
              )}
              <Stack direction="row" justifyContent={"space-around"}>
                <Button
                  color="inherit"
                  sx={{ whiteSpace: "pre" }}
                  onClick={() => setOpenHistory(true)}
                >
                  {HYSTORY}
                </Button>
                <Button
                  color="secondary"
                  onClick={() => setOpenUpload(true)}
                  disabled={loadingCurrent}
                >
                  {UPDATE}
                </Button>
              </Stack>
            </Box>
          </Grid>
          <Grid xs={4} pr="24px" textAlign={"center"}>
            <Box sx={filterBox} pt="33px !important" pb="33px !important">
              <img src={FechaIcon} alt="" />
              <Typography pt="24px" sx={titleMenuBox}>
                Fechas
              </Typography>
              <Typography pt="12px" pb="31px" sx={subMenuBox}>
                subtítulo
              </Typography>
              <Button color="secondary" disabled>
                Próximamente
              </Button>
            </Box>
          </Grid>
          <Grid xs={4} textAlign={"center"}>
            <Box sx={filterBox} pt="33px !important" pb="33px !important">
              <img src={DevolucionIcon} alt="" />
              <Typography pt="24px" sx={titleMenuBox}>
                Movimientos
              </Typography>
              <Typography pt="12px" pb="33px" sx={subMenuBox}>
                subtítulo
              </Typography>
              <Button color="secondary" disabled>
                Próximamente
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SettingsMenu;
