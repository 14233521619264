import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import SearchCash from "./searchCash";
import { ChipsCash } from "./chipsCash";
import { MenuCash } from "./menuCash";
import { ChangeLogModal, ExportButton, NoResult } from "src/components";
import { StyledTableCell } from "src/components/tables/tableStyle";
import { paginatorTheme } from "src/components/paginator/paginatorStyle";
import { boxNoPadding } from "src/components/box/styles";
import { contTitle, subTable } from "src/components/typography/styles";
import { useLazyQuery } from "@apollo/client";
import { GET_ORIGIN } from "src/services/graphql/queries";
import { SkeletonTableCash } from "./skeletonsCash";
import { createPeriodList, getCurrentPeriod } from "src/utils/dates";
import { DeleteModal } from "../../credits/components/deleteModal";
import EditPayment from "./EditPayment";
import { hasPermissions } from "src/utils/hasPermissions";
import constants from "src/constants/constants";
import { usePayment } from "src/pages/collection/hooks/usePayment";
import { renderAmount } from "src/utils/numberUtils";
import SplitPayment from "./SplitPayment";

const {
  PAYMENT_TYPE: { PAC, PAT, CAJA, EXCESS },
} = constants;

export const TagsCash = ({ estado }: any) => {
  switch (true) {
    case estado === PAC:
      return <label className="tagEstadoCasos tagPAC">{PAC}</label>;
    case estado === PAT:
      return <label className="tagEstadoCasos tagPAT">{PAT}</label>;
    case estado === CAJA:
      return <label className="tagEstadoCasos tagCaja">{CAJA}</label>;
    case estado === EXCESS:
      return <label className="tagEstadoCasos tagRevision">{EXCESS}</label>;
    default:
      return (
        <label className="tagEstadoCasos tagRechazado">
          {estado !== null ? estado : "Otro"}
        </label>
      );
  }
};

type TablePaymentProps = {
  period: string;
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackInfo: React.Dispatch<React.SetStateAction<any>>;
};

export default function TableRegistersCash({
  period,
  refresh,
  setRefresh,
  setSnackInfo,
}: TablePaymentProps) {
  const [modalBuscar, setModalBuscar] = useState(false);
  const [filters, setFilters] = useState({
    contributorRut: null,
    paymentPeriod: null,
    payerRut: null,
    paymentType: null,
  });
  const [origen, setOrigen] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [modalHistory, setModalHistory] = useState<boolean>(false);
  const [modalDelete, setModalDelete] = useState<boolean>(false);
  const [modalEdit, setModalEdit] = useState<boolean>(false);
  const [modalSplit, setModalSplit] = useState<boolean>(false);
  const [rowData, setRowData] = useState({
    id: "",
    contributorRut: "",
    contributorName: "",
    payerRut: "",
    payerName: "",
    paymentDate: "",
    paymentPeriod: "",
    amount: 0,
    paymentType: "",
  });
  const canUpdate = hasPermissions().update;

  const {
    getPaymentData,
    paymentCount,
    paymentRows,
    paymentLoading,
    paymentError,
  } = usePayment();

  const [getOrigen, { data: dataOrigen }] = useLazyQuery(GET_ORIGIN, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        specificType: 1,
      },
    },
  });

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    getPaymentData({
      ...filters,
      collectionPeriod: period,
      isActive: true,
      limit: rowsPerPage,
      offset: page * rowsPerPage - rowsPerPage,
    });
    getOrigen();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataOrigen?.period?.collection.payment.paymentType.allBy) {
      const names =
        dataOrigen?.period?.collection.payment.paymentType.allBy.map(
          (origin: any) => origin.name
        );
      setOrigen(names);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataOrigen]);

  useEffect(() => {
    getPaymentData({
      ...filters,
      collectionPeriod: period,
      isActive: true,
      limit: rowsPerPage,
      offset: page * rowsPerPage - rowsPerPage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    setPage(1);
    getPaymentData({
      ...filters,
      collectionPeriod: period,
      isActive: true,
      limit: rowsPerPage,
      offset: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (refresh === true) {
      setPage(1);
      getPaymentData({
        collectionPeriod: period,
        contributorRut: null,
        payerRut: null,
        paymentPeriod: null,
        paymentType: null,
        isActive: true,
        limit: rowsPerPage,
        offset: 0,
      });
      setRefresh(false);
      setFilters({
        contributorRut: null,
        paymentPeriod: null,
        payerRut: null,
        paymentType: null,
      });
    }
    // eslint-disable-next-line
  }, [refresh]);

  return (
    <Box sx={boxNoPadding} mt="16px" mb="24px">
      <ChangeLogModal
        onOpen={modalHistory}
        onClose={() => setModalHistory(false)}
        id={rowData.id}
        logTypeId={22}
        data-testid="record-modal"
      />
      <DeleteModal
        onOpen={modalDelete}
        onClose={() => setModalDelete(false)}
        id={rowData.id}
        rut={rowData.contributorRut}
        payerRut={rowData.payerRut}
        period={period}
        data-testid="delete-modal"
        setRefresh={setRefresh}
        type={2}
      />
      <EditPayment
        onOpen={modalEdit}
        handleClose={() => setModalEdit(false)}
        info={rowData}
        setRefresh={setRefresh}
        setSnackInfo={setSnackInfo}
        data-testid="edit-modal"
        originOptions={origen}
      />
      <SplitPayment
        onOpen={modalSplit}
        handleClose={() => setModalSplit(false)}
        type={1}
        info={rowData}
        setRefresh={setRefresh}
        setSnackInfo={setSnackInfo}
        data-testid="split-modal"
      />
      {paymentLoading ? (
        <SkeletonTableCash
          grid={true}
          gridExtra={true}
          rows={3}
          columns={6}
          extra={true}
          table={"full"}
          data-testid="skeleton-table"
        />
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                pt="15px"
                pr="24px"
              >
                <Typography sx={contTitle} pt="11px" pl="24px">
                  Listado de Recaudación de PAC, PAT y Caja
                </Typography>{" "}
                {(paymentCount !== 0 ||
                  ((filters.contributorRut !== "" ||
                    filters.paymentPeriod !== "" ||
                    filters.payerRut !== "" ||
                    filters.paymentType !== "") &&
                    paymentCount === 0)) && (
                  <Stack direction={"row"}>
                    <div>
                      <Button
                        data-testid="ver-filtros"
                        color="inherit"
                        onClick={() => setModalBuscar(true)}
                      >
                        Ver filtros
                      </Button>
                    </div>
                    <ExportButton
                      period={period}
                      contributorRut={filters.contributorRut || null}
                      paymentPeriod={filters.paymentPeriod || null}
                      payerRut={filters.payerRut || null}
                      paymentType={filters.paymentType}
                      isActive={true}
                      includePrevired={false}
                      type={2}
                      disabled={paymentCount === 0}
                    />
                  </Stack>
                )}
              </Stack>
            </Grid>
            <ChipsCash filtros={filters} setFiltros={setFilters} />
          </Grid>
          {paymentError ? (
            <NoResult
              titulo={"No se pudo cargar la tabla"}
              subtitulo={
                "No fue posible mostrar la información debido a un problema de conexión. Intenta recargar la página para solucionar el problema."
              }
              error
            />
          ) : (
            <>
              <SearchCash
                onOpen={modalBuscar}
                handleClose={() => setModalBuscar(false)}
                filtros={filters}
                setFiltros={setFilters}
                options={createPeriodList(false)}
                origen={origen}
                data-testid="search-modal"
              />
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "none",
                  borderRadius: "none",
                  paddingTop: "17px",
                }}
              >
                {paymentCount === 0 ? (
                  filters.contributorRut === ("" || null) &&
                  filters.paymentPeriod === ("" || null) &&
                  filters.paymentType === ("" || null) &&
                  filters.payerRut === ("" || null) ? (
                    <NoResult
                      titulo={"No se han cargado archivos de recaudación"}
                      subtitulo={"Cargue información para ver el listado"}
                      dontSearch
                    />
                  ) : (
                    <NoResult
                      titulo={"Sin Resultados"}
                      subtitulo={"No se encontraron resultados para esta tabla"}
                    />
                  )
                ) : (
                  <>
                    <Table sx={{ minWidth: 650 }} aria-label="customized table">
                      <TableHead className="color-head-table">
                        <TableRow>
                          <StyledTableCell>
                            RUT y Nombre <br></br>
                            cliente
                          </StyledTableCell>
                          <StyledTableCell>
                            RUT y Nombre <br></br>
                            pagador
                          </StyledTableCell>
                          <StyledTableCell>
                            Periodo <br></br> remuneración
                          </StyledTableCell>
                          <StyledTableCell>Monto pago</StyledTableCell>
                          <StyledTableCell>Fecha Pago</StyledTableCell>
                          <StyledTableCell>Origen Pago</StyledTableCell>

                          <StyledTableCell>Acciones</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {paymentRows.map((file: any) => {
                        return (
                          <TableBody>
                            <TableRow data-testid="withData" key={file.id}>
                              <TableCell>
                                <Typography data-testid="rut" variant="h5">
                                  {file.contributorRut}
                                </Typography>
                                <Typography sx={subTable}>
                                  {file.contributorName}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  {file.payerRut}
                                </Typography>
                                <Typography sx={subTable}>
                                  {file.payerName}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  {getCurrentPeriod(file.paymentPeriod)[0]}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  ${renderAmount(file.amount)}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  {file.paymentDate
                                    .slice(0, 10)
                                    .split("-")
                                    .join("/")}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {<TagsCash estado={file.paymentType} />}
                              </TableCell>
                              <TableCell>
                                <MenuCash
                                  info={file}
                                  setModalHistory={setModalHistory}
                                  setModalDelete={setModalDelete}
                                  setModalEdit={setModalEdit}
                                  setModalSplit={setModalSplit}
                                  setRowData={setRowData}
                                  canUpdate={canUpdate}
                                  paymentType={file.paymentType}
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      })}
                    </Table>
                    <Grid alignItems="center" pb="15px" pt="24px">
                      <ThemeProvider theme={paginatorTheme}>
                        <TablePagination
                          rowsPerPageOptions={[10, 20, 50]}
                          component="div"
                          count={paymentCount}
                          rowsPerPage={rowsPerPage}
                          page={page - 1}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          labelRowsPerPage={"Mostrar por página"}
                          sx={{ mt: "24px", mb: "24px" }}
                          labelDisplayedRows={(e) => {
                            return (
                              "" +
                              (page || 0) +
                              " de " +
                              Math.ceil(paymentCount / rowsPerPage)
                            );
                          }}
                        />
                      </ThemeProvider>
                    </Grid>
                  </>
                )}
              </TableContainer>
            </>
          )}
        </>
      )}
    </Box>
  );
}
