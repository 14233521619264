import { useEffect, useReducer, useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Stack,
  Grid,
  Tooltip,
} from "@mui/material";
import {
  subText20,
  detailText,
  littleTitle,
  littleDetail,
} from "src/components/typography/styles";
import { filterBox } from "src/components/box/styles";
import {
  AlertSnackbar,
  Breadcrum,
  TabPanel,
  UploadButton,
} from "src/components";
import {
  RecordTable,
  SkeletonHeaderC,  
  TableContract,
} from "./index";
import { useLocation } from "react-router-dom";
import { getCurrentPeriod, getPreviousPeriod } from "src/utils/dates";
import { INSERT_JSON_CONTRACT, INSERT_FILE_CONTRACT } from "src/services/graphql/mutations";
import { useMutation } from "@apollo/client";
import constants from "src/constants/constants";
import { hasPermissions } from "src/utils/hasPermissions";
import { useCurrency } from "src/hooks/useCurrency";
import { useLogs } from "src/hooks/useLogs";
import { systemModules } from "src/utils/selectors";
import {
  SNACK_ACTION_TYPE,
  SNACKINFO_INITIAL_STATE,
  snackInfoReducer,
} from "src/reducers/snackInfoReducer";
import MissingPeriod from "src/pages/MissingPeriod";

const {
  CLOSE_SNACK_INFO,
  SET_SNACK_ERROR,
  SET_SNACK_SUCCESS,
  SET_SNACK_WARNING,
} = SNACK_ACTION_TYPE;

const {
  PAGES: {
    MODULES: { CREDITS },
    DISPLAY_NAME: { CREDITS: CREDITS_DISPLAY },
  },
  SNACKBAR_MESSAGE: {
    CREDITS_UPLOAD_ERROR,
    CREDITS_UPLOAD_SUCCESS,
    CREDITS_UPLOAD_WARNING,
  },
} = constants;

const Credits = () => {
  const [valorTab, setValorTab] = useState(0);
  const [openLoad, setOpenLoad] = useState<boolean>(false);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [snackInfoState, snackInfoDispatch] = useReducer(
    snackInfoReducer,
    SNACKINFO_INITIAL_STATE
  );
  const [hasUF, setHasUF] = useState(false);
  const location = useLocation();
  const period = location.state?.period || "";

  const { currencyInfo, oneBy, loading: loadingCurrency } = useCurrency()
  const getCurrencyInfo = () => {
    currencyInfo({
      currencyType: 1,
      periodName: getPreviousPeriod(period)[3],
    })
  }

  const { getLog, rows: rowsLogs, loading: loadingLogs } = useLogs();
  const getLatestUploadedFile = () => {
    getLog({
      logTypeId: systemModules(CREDITS),
      fkId: parseInt(period),
    });
  };

  const [insertJson] = useMutation(INSERT_JSON_CONTRACT, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        collectionPeriod: period,        
      },
    },
    onError: (error) => {
      snackInfoDispatch({
        type: SET_SNACK_ERROR,
        payload: error?.graphQLErrors[0]?.message || CREDITS_UPLOAD_ERROR,
      });
      handleCloseLoading();
    },
  });

  const [insertXlsx] = useMutation(INSERT_FILE_CONTRACT, {
    fetchPolicy: "network-only",
    onError: () => {
      snackInfoDispatch({
        type: SET_SNACK_WARNING,
        payload: CREDITS_UPLOAD_WARNING,
      });
      handleCloseLoading();
    },
  });

  const handleInsertDB = async () => {
    if (period) {
      await insertJson().then(async (res) => {
        if (
          res?.data?.period !== null &&
          res?.data?.period !== undefined
        ) {
          await insertXlsx({
            variables: {
              input: {
                logId:
                  res.data.period.collection.contributorContract.bulkCreate.data.logId,
              },
            },
          }).finally(() => {
            setRefresh(true);
            snackInfoDispatch({
              type: SET_SNACK_SUCCESS,
              payload: CREDITS_UPLOAD_SUCCESS,
            });
          });
        }
      });
      handleCloseLoading();
      getLatestUploadedFile();
    }
  };

  const handleCloseLoading = () => {
    setOpenLoad(false);
    setUploadInProgress(false);
  };

  useEffect(() => {
    if (period) {    
      getCurrencyInfo();
      getLatestUploadedFile();      
    }    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 

  useEffect(() => {
    oneBy ? setHasUF(true) : setHasUF(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneBy]);

  if (!period) {
    return <MissingPeriod displayName={CREDITS_DISPLAY} />;
  }

  return (
    <>
      <AlertSnackbar        
        onClose={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        open={snackInfoState.open}
        status={snackInfoState.status}
        message={snackInfoState.message}
      />
      <Box pl="32px" pr="32px">
        <Typography variant="h1" pt="24px" mb="16px">
          {CREDITS_DISPLAY} - {getCurrentPeriod(period)[0]}
        </Typography>
        <Breadcrum
          back1="Inicio"
          link1="/"
          now={CREDITS_DISPLAY + " - " + getCurrentPeriod(period)[0]}
        />
        <Box sx={filterBox} mt="16px" mb="24px">
          {loadingCurrency || loadingLogs ? (
            <SkeletonHeaderC />
          ) : (
            <Grid container>
              <Grid xs={7}>
                <Typography sx={littleTitle} component="div">
                  Cargar acreencias
                </Typography>
                <Typography
                  sx={detailText}
                  component="div"
                  pt="16px"
                  pb="8px   "
                >
                  Carga información específica de una acreencia.
                </Typography>
                <Typography sx={subText20} component="div">
                  {rowsLogs.length > 0
                    ? "Última carga realizada el " +
                      rowsLogs[0]?.logDate.slice(0, 10).split("-").join("/") +
                      " a las " +
                      rowsLogs[0]?.logDate.slice(11, 16) +
                      " hrs: con un total de " +
                      rowsLogs[0]?.description +
                      " registros."
                    : "Comienza cargando información."}
                </Typography>
              </Grid>
              <Grid xs={5} pt="36px">
                <Stack
                  direction="row"
                  textAlign="right"
                  justifyContent="flex-end"
                >
                  <Typography sx={littleDetail} component="div" pr="16px">
                    Formato aceptados: .xlsx <br></br>
                    Peso máximo de 4MB
                  </Typography>
                  <Tooltip
                    open={!hasUF}
                    title="Debes cargar el valor UF del periodo anterior"
                    placement="top-end"
                    arrow
                  >
                    <span>
                      <UploadButton
                        disabled={!hasUF || !hasPermissions().uploadFile}
                        accept=".xlsx"
                        maxSize={4}
                        onUpload={() => handleInsertDB()}
                        openLoad={openLoad}
                        setOpenLoad={setOpenLoad}
                        uploadInProgress={uploadInProgress}
                        setUploadInProgress={setUploadInProgress}
                        period={period}
                        module={CREDITS}
                      />
                    </span>
                  </Tooltip>
                </Stack>
              </Grid>
            </Grid>
          )}
        </Box>
        <Tabs
          sx={{ width: "80%" }}
          textColor="primary"
          value={valorTab}
          onChange={(event, value) => setValorTab(value)}
        >
          <Tab label={CREDITS_DISPLAY} />
          <Tab label="Historial de carga" />
          <Tab label="Registros eliminados" />
        </Tabs>
        <TabPanel value={valorTab} index={0}>
          <TableContract
            refresh={refresh}
            setRefresh={setRefresh}
            period={period}
            isActive={true}
          />
        </TabPanel>
        <TabPanel value={valorTab} index={1}>
          <RecordTable
            refresh={refresh}
            setRefresh={setRefresh}
            period={period}
          />
        </TabPanel>
        <TabPanel value={valorTab} index={2}>
        <TableContract
            refresh={refresh}
            setRefresh={setRefresh}
            period={period}
            isActive={false}
          />          
        </TabPanel>
      </Box>
    </>
  );
};

export default Credits;
