import { Alert, AlertColor, IconButton, Snackbar } from "@mui/material";
import snackClose from "src/assets/icons/closeIcon.svg";
import { style, iconStyle } from "src/components/alert/styles";

type AlertSnackbarProps = {
  open: boolean;
  onClose: () => void;  
  status: AlertColor | undefined;
  message: string;
};

const AlertSnackbar = ({
  open,
  onClose,  
  status,
  message,
}: AlertSnackbarProps) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={() => onClose()}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert severity={status} sx={{...style(status)}} iconMapping={iconStyle(status)}>
        {message}
        <IconButton
          aria-label="close"
          sx={{ p: 0.5, ml: "7px", mb: "3px" }}
          onClick={() => onClose()}
          data-testid="closeButton"
        >
          <img src={snackClose} alt="snack close" />
        </IconButton>
      </Alert>
    </Snackbar>
  );
};

export default AlertSnackbar;
