import { useState, useEffect } from "react";
import { createPeriodList, getCurrentPeriod } from "src/utils/dates";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import SearchC from "./searchC";
import { ChipsC } from "./chipsC";
import { MenuC } from "./menuC";
import { ChangeLogModal, ExportButton, NoResult } from "src/components";
import { StyledTableCell } from "src/components/tables/tableStyle";
import { paginatorTheme } from "src/components/paginator/paginatorStyle";
import { boxNoPadding } from "src/components/box/styles";
import {
  contTitle,
  subTable,
  textTooltip,
} from "src/components/typography/styles";
import { SkeletonTableC } from "./skeletonsC";
import Info from "src/assets/icons/info.svg";
import { BootstrapTooltip } from "src/components/header/drawerLeft";
import { DeleteModal } from "./deleteModal";
import { AddCredits } from "../index";
import { hasPermissions } from "src/utils/hasPermissions";
import { useContract } from "../../hooks/useContract";
import { renderAmount } from "src/utils/numberUtils";
import constants from "src/constants/constants";

const {
  COMPONENTS: {
    BUTTONS: { FILTERS, LOG_HISTORY },
  },
} = constants;

const minWidthA = { minWidth: "124px" };
const minWidthB = { minWidth: "113px" };

const checkNotAvailable = (amount: any, addSign: boolean) => {
const displayAmount = addSign ? "$" : "";  
  return amount === -1 ? "No disponible" : displayAmount + amount.toLocaleString("es-cl");
};

const TableContract = (props: any) => {
  const [modalBuscar, setModalBuscar] = useState(false);
  const [filters, setFilters] = useState({
    contributorRut: null,
    paymentPeriod: null,
  });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [modalHistory, setModalHistory] = useState<boolean>(false);
  const [modalDelete, setModalDelete] = useState<boolean>(false);
  const [modalEdit, setModalEdit] = useState<boolean>(false);
  const [rowData, setRowData] = useState({
    id: "",
    contributorRut: "",
    contributorName: "",
    paymentUf: 0,
    paymentPeriod: "",
    contractedAmount: 0,
    workerType: "",
  });
  const canUpdate = hasPermissions().update;

  const {
    getContractData,
    contractCount,
    contractRows,
    contractLoading,
    contractError,
  } = useContract();

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const TipoTrabajador = () => {
    return (
      <>
        <Typography sx={textTooltip}>D: Dependiente</Typography>
        <Typography sx={textTooltip}>P: Pensionado</Typography>
        <Typography sx={textTooltip}>V: Voluntario</Typography>
        <Typography sx={textTooltip}>I: Independiente</Typography>
      </>
    );
  };

  const handleDetails = (elem: any) => {
    setModalHistory(true);
    setRowData({
      id: elem.id,
      contributorRut: elem.contributorRut,
      contributorName: elem.contributorName,
      paymentUf: elem.paymentUf,
      paymentPeriod: elem.paymentPeriod,
      contractedAmount: elem.contractedAmount,
      workerType: elem.workerType,
    });
  };

  useEffect(() => {
    getContractData({
      collectionPeriod: props.period,
      contributorRut: null,
      paymentPeriod: null,
      addOverdue: false,
      isActive: props.isActive,
      limit: rowsPerPage,
      offset: page * rowsPerPage - rowsPerPage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getContractData({
      ...filters,
      collectionPeriod: props.period,
      addOverdue: false,
      isActive: props.isActive,
      limit: rowsPerPage,
      offset: page * rowsPerPage - rowsPerPage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    setPage(1);
    getContractData({
      ...filters,
      collectionPeriod: props.period,
      addOverdue: false,
      isActive: props.isActive,
      limit: rowsPerPage,
      offset: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (props.refresh === true) {
      setPage(1);
      getContractData({
        collectionPeriod: props.period,
        contributorRut: null,
        paymentPeriod: null,
        isActive: props.isActive,
        addOverdue: false,
        limit: rowsPerPage,
        offset: 0,
      });
      props.setRefresh(false);
      setFilters({
        contributorRut: null,
        paymentPeriod: null,
      });
    }
    // eslint-disable-next-line
  }, [props.refresh]);

  return (
    <Box sx={boxNoPadding} mt="16px" mb="24px">
      <ChangeLogModal
        onOpen={modalHistory}
        onClose={() => setModalHistory(false)}
        id={rowData.id}
        logTypeId={19}
        data-testid="record-modal"
      />
      <DeleteModal
        onOpen={modalDelete}
        onClose={() => setModalDelete(false)}
        id={rowData.id}
        contributorRut={rowData.contributorRut}
        payerRut={null}
        period={props.period}
        data-testid="delete-modal"
        setRefresh={props.setRefresh}
        type={1}
      />
      <AddCredits
        onOpen={modalEdit}
        handleClose={() => setModalEdit(false)}
        info={rowData}
        setRefresh={props.setRefresh}
        data-testid="edit-modal"
      />
      {contractLoading ? (
        <SkeletonTableC grid={true} table={"full"} testid="contract-skeleton" />
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                pt="15px"
                pr="24px"
              >
                <Typography sx={contTitle} pt="11px" pl="24px">
                  {props.isActive
                    ? "Listado de acreencias"
                    : "Listado de registros eliminados"}
                </Typography>{" "}
                {(contractCount !== 0 ||
                  ((filters.contributorRut !== "" ||
                    filters.paymentPeriod !== "") &&
                    contractCount === 0)) && (
                  <Stack direction={"row"}>
                    <div>
                      <Button
                        data-testid="ver-filtros"
                        color="inherit"
                        onClick={() => setModalBuscar(true)}
                      >
                        {FILTERS}
                      </Button>
                    </div>
                    <ExportButton
                      period={props.period}
                      contributorRut={filters.contributorRut || null}
                      paymentPeriod={filters.paymentPeriod || null}
                      isActive={props.isActive}
                      addOverdue={false}
                      type={1}
                      disabled={contractCount === 0}
                    />
                  </Stack>
                )}
              </Stack>
            </Grid>
            <ChipsC filtros={filters} setFiltros={setFilters} />
          </Grid>
          {contractError ? (
            <NoResult
              titulo={"No se pudo cargar la tabla"}
              subtitulo={
                "No fue posible mostrar la información debido a un problema de conexión. Intenta recargar la página para solucionar el problema."
              }
              error
            />
          ) : (
            <>
              <SearchC
                onOpen={modalBuscar}
                handleClose={() => setModalBuscar(false)}
                filtros={filters}
                setFiltros={setFilters}
                options={createPeriodList(false)}
                data-testid="search-modal"
              />
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "none",
                  borderRadius: "none",
                  paddingTop: "17px",
                }}
              >
                {contractCount === 0 ? (
                  filters.contributorRut === "" &&
                  filters.paymentPeriod === "" ? (
                    <NoResult
                      titulo={"No se han cargado archivos de acreencias"}
                      subtitulo={"Cargue información para ver el listado"}
                      dontSearch
                    />
                  ) : (
                    <NoResult
                      titulo={"Sin Resultados"}
                      subtitulo={"No se encontraron resultados para esta tabla"}
                    />
                  )
                ) : (
                  <>
                    <Table sx={{ minWidth: 650 }} aria-label="customized table">
                      <TableHead className="color-head-table">
                        <TableRow>
                          <StyledTableCell sx={{ minWidth: "168px" }}>
                            RUT y Nombre
                          </StyledTableCell>
                          <StyledTableCell sx={minWidthA}>
                            Periodo venta
                          </StyledTableCell>
                          <StyledTableCell sx={minWidthA}>
                            Periodo <br></br> remuneración
                          </StyledTableCell>
                          <StyledTableCell sx={minWidthA}>
                            Periodo <br></br> recaudación
                          </StyledTableCell>
                          <StyledTableCell sx={minWidthB}>
                            Prima UF
                          </StyledTableCell>
                          <StyledTableCell sx={minWidthB}>
                            Pactado Pesos
                          </StyledTableCell>
                          <StyledTableCell sx={minWidthB}>
                            <Stack
                              direction="row"
                              justifyContent="space-evenly"
                            >
                              Tipo <br></br> trabajador{" "}
                              <BootstrapTooltip
                                title={<TipoTrabajador />}
                                arrow
                                placement="right"
                              >
                                <img src={Info} alt="info" />
                              </BootstrapTooltip>
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell sx={minWidthB}>
                          Precio base <br></br> del plan
                          </StyledTableCell>
                          <StyledTableCell sx={minWidthB}>
                          Suma factores de riesgo
                          </StyledTableCell>
                          <StyledTableCell sx={minWidthB}>
                          Precio plan complementario
                          </StyledTableCell>
                          <StyledTableCell sx={minWidthB}>
                            Valor GES
                          </StyledTableCell>
                          <StyledTableCell sx={minWidthB}>
                          Beneficios <br></br> adicionales
                          </StyledTableCell>
                          <StyledTableCell
                            className="headerTableAcciones"
                            align="center"
                            sx={{ minWidth: "80px" }}
                          >
                            Acciones
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {contractRows.map((row: any) => {
                        return (
                          <TableBody>
                            <TableRow data-testid="withData" key={row.id}>
                              <TableCell>
                                <Typography
                                  data-testid="contributorRut"
                                  variant="h5"
                                >
                                  {row.contributorRut}
                                </Typography>
                                <Typography sx={subTable}>
                                  {row.contributorName}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  {getCurrentPeriod(row.sellPeriod)[0]}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  {getCurrentPeriod(row.paymentPeriod)[0]}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  {getCurrentPeriod(row.collectionPeriod)[0]}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  {(
                                    Math.trunc(row.paymentUf * 1000) / 1000
                                  ).toLocaleString("ES-CL")}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  ${renderAmount(row.contractedAmount)}
                                </Typography>
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                <Typography variant="h5">
                                  {row.workerType}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  {checkNotAvailable(row.basePlanAmount, true)}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  {checkNotAvailable(row.riskFactor, false)}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  {checkNotAvailable(row.complementaryPlanAmount, true)}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  {checkNotAvailable(row.gesAmount, true)}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  {checkNotAvailable(row.additionalBenefitsAmount, true)}
                                </Typography>
                              </TableCell>
                              <TableCell
                                className="tableBodyAsignar"
                                align="center"
                                sx={{ zIndex: 999 }}
                              >
                                {props.isActive ? (
                                  <MenuC
                                    info={row}
                                    setModalHistory={setModalHistory}
                                    setModalDelete={setModalDelete}
                                    setModalEdit={setModalEdit}
                                    setRowData={setRowData}
                                    canUpdate={canUpdate}
                                  />
                                ) : (
                                  <Button
                                    color="secondary"
                                    size="small"
                                    sx={{ padding: "10px 6px !important" }}
                                    onClick={() => {
                                      handleDetails(row);
                                    }}
                                  >
                                    {LOG_HISTORY}
                                  </Button>
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      })}
                    </Table>
                  </>
                )}
              </TableContainer>
              {contractCount !== 0 && (
                <Grid alignItems="center" pb="2px">
                  <ThemeProvider theme={paginatorTheme}>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 50]}
                      component="div"
                      count={contractCount}
                      rowsPerPage={rowsPerPage}
                      page={page - 1}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage={"Mostrar por página"}
                      sx={{ mt: "24px", mb: "24px" }}
                      labelDisplayedRows={(e) => {
                        return (
                          page + " de " + Math.ceil(contractCount / rowsPerPage)
                        );
                      }}
                    />
                  </ThemeProvider>
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default TableContract;
