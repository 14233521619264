import { useEffect, useReducer, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  blackBold,
  littleTitle,
  modalSubtitle,
  purpleLittle,
} from "src/components/typography/styles";
import {
  AlertSnackbar,
  CustomInput,
  CustomModal,
  CustomModalTitle,
  CustomSelect,
} from "src/components";
import { useMutation } from "@apollo/client";
import { UPDATE_PAYMENT } from "src/services/graphql/mutations";
import constants from "src/constants/constants";
import {
  SNACK_ACTION_TYPE,
  SNACKINFO_INITIAL_STATE,
  snackInfoReducer,
} from "src/reducers/snackInfoReducer";

const { CLOSE_SNACK_INFO, SET_SNACK_ERROR, SET_SNACK_SUCCESS } =
  SNACK_ACTION_TYPE;
const {
  COMPONENTS: {
    BUTTONS: { CANCEL, SAVE },
  },
  SNACKBAR_MESSAGE: {
    COLLECTION: { PAYMENTS_UPDATE_ERROR, PAYMENTS_UPDATE_SUCCESS },
  },
} = constants;

const EditCollection = ({
  onOpen,
  handleClose,
  info,
  setRefresh,
  classOptions,
}: any) => {
  const [disabled, setDisabled] = useState(true);
  const [observation, setObservation] = useState(info?.observation);
  const [paymentStatus, setPaymentStatus] = useState(
    info?.paymentClassification
  );
  const [snackInfoState, snackInfoDispatch] = useReducer(
    snackInfoReducer,
    SNACKINFO_INITIAL_STATE
  );
  const [updateCollection, { loading }] = useMutation(UPDATE_PAYMENT, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      setRefresh(true);
      snackInfoDispatch({
        type: SET_SNACK_SUCCESS,
        payload: PAYMENTS_UPDATE_SUCCESS,
      });
      handleClose();
    },
    onError: () => {
      snackInfoDispatch({
        type: SET_SNACK_ERROR,
        payload: PAYMENTS_UPDATE_ERROR,
      });
      handleClose();
    },
  });

  const getClassOptions = () => {
    let optionsArray: any[] = [];
    if (classOptions) {
      classOptions.forEach((element: any) => optionsArray.push(element));
    }
    return optionsArray;
  };

  const findClass = (id: string) => {
    let option = null;
    if (classOptions) {
      classOptions.forEach((element: any) => {
        // eslint-disable-next-line eqeqeq
        if (id == element.id) {
          option = element.statusId;
        }
      });
    }
    return option ? parseInt(option) : null;
  };

  const findClassByName = (name: string) => {
    let option = null;
    if (classOptions) {
      classOptions.forEach((element: any) => {
        // eslint-disable-next-line eqeqeq
        if (name == element.name) {
          option = element.id;
        }
      });
    }
    return option;
  };

  const [classification, setClassification] = useState<any>(
    findClassByName(info?.paymentClassification)
  );

  const handleClassChange = (id: string) => {
    setClassification(id);
    setPaymentStatus(findClass(id));
  };

  const handleSave = () => {
    updateCollection({
      variables: {
        input: {
          id: parseInt(info?.id),
          statusId: paymentStatus,
          observation: observation,
        },
      },
    });
  };

  useEffect(() => {
    if (onOpen === true) {
      setClassification(findClassByName(info?.paymentClassification));
      info?.observation === null
        ? setObservation(null)
        : setObservation(info?.observation);
      setPaymentStatus(
        findClass(findClassByName(info?.paymentClassification)!)
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOpen]);

  useEffect(() => {
    if (paymentStatus || observation) {
      setDisabled(
        paymentStatus ===
          findClass(findClassByName(info?.paymentClassification)!) &&
          observation === info?.observation
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatus, observation]);

  return (
    <>
      <AlertSnackbar        
        onClose={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        open={snackInfoState.open}
        status={snackInfoState.status}
        message={snackInfoState.message}
      />
      <CustomModal
        maxWidth="md"
        open={onOpen}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <CustomModalTitle id="addCredits" onClose={handleClose}>
          <Typography sx={littleTitle} textAlign="center" pt="10px">
            Editar Observación
          </Typography>
          <Typography sx={modalSubtitle} textAlign="center">
            Modifica los campos necesarios para actualizar la información.
          </Typography>
        </CustomModalTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="column"
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid sx={{ margin: "0", width: "100%" }} container spacing={2}>
              <Grid
                item
                xs={6}
                p="0 !important"
                pr="24px !important"
                pb="24px !important"
              >
                <Stack direction="column">
                  <Typography sx={purpleLittle} component="div">
                    Nombre y RUT cliente
                  </Typography>
                  <Typography sx={blackBold} component="div">
                    {info?.contributorName + " " + info?.contributorRut}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} p="0 !important" pb="24px !important">
                <Stack direction="column">
                  <Typography sx={purpleLittle} component="div">
                    Nombre y RUT pagador
                  </Typography>
                  <Typography sx={blackBold} component="div">
                    {info?.payerName + " " + info?.payerRut}
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
                p="0 !important"
                pr="24px !important"
                pb="24px !important"
              >
                <Stack direction="column">
                  <Typography sx={purpleLittle} component="div">
                    Origen
                  </Typography>
                  <Typography sx={blackBold} component="div">
                    {info?.paymentType}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} p="0 !important" pb="24px !important">
                <label className="grayLabelForm">Clasificación</label>
                <CustomSelect
                  titulo={"Seleccione"}
                  hideTitle={true}
                  value={classification}
                  onChange={(e: any) => {
                    handleClassChange(e.target.value);
                  }}
                  data={getClassOptions().map((elem: any) => elem.name)}
                />
              </Grid>
              <Grid item xs={12} p="0 !important">
                <label className="grayTitleAlt">
                  Observación: Escribe o modifica.
                </label>
                <CustomInput
                  value={observation}
                  onChange={(e: any) => {
                    setObservation(e.target.value);
                  }}
                  sx={{ paddingTop: "6px" }}
                  placeholder="Escribe una observación, 150 carácteres máximo."
                  fullWidth
                  multiline
                  inputProps={{
                    maxLength: 150,
                    "data-testid": "input-observation",
                  }}
                />
                <div className="contador">
                  <label className="textoContador">Texto</label>
                  <label className="textoContador">
                    {observation?.length || 0}/150
                  </label>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              onClick={handleClose}
              color="secondary"
              sx={{ width: "150px" }}
            >
              {CANCEL}
            </Button>
            {loading ? (
              <Button
                color="primary"
                sx={{ height: "50px", minWidth: "150px" }}
              >
                <div className="spinnerButton"></div>
              </Button>
            ) : (
              <Button
                sx={{ width: "150px" }}
                onClick={handleSave}
                disabled={disabled}
              >
                {SAVE}
              </Button>
            )}
          </Grid>
        </DialogActions>
      </CustomModal>
    </>
  );
};

export default EditCollection;
