const regex = /^\d{1,9}?$/;

export const renderAmount = (amount: any) => {
  return amount ? amount.toLocaleString("es-cl") : 0;
};

export const testNumber = (number: string) => {
  number = number.replace(/^0+/, "");
  if (regex.test(number)) {
    return number;
  }
}