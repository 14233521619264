import { useEffect, useReducer, useState } from "react";
import { Box, Typography, Stack, Grid, Tabs, Tab } from "@mui/material";
import {
  Alerts,
  AlertSnackbar,
  Breadcrum,
  ExportButton,
  TabPanel,
  UploadButton,
} from "src/components";
import { filterBox } from "src/components/box/styles";
import {
  detailText,
  littleDetail,
  littleTitle,
  subText20,
  titleLight,
} from "src/components/typography/styles";
import TableExcess from "./components/tableExcess";
import { useLocation } from "react-router-dom";
import { getCurrentPeriod } from "src/utils/dates";
import { useMutation } from "@apollo/client";
import {
  INSERT_JSON_SURPLUS,
  INSERT_FILE_SURPLUS,
} from "src/services/graphql/mutations";
import { SkeletonHeaderC } from "src/pages/collection/credits/components/skeletonsC";
import { RecordTableCash } from "src/pages/collection/Cash";
import { hasPermissions } from "src/utils/hasPermissions";
import { useLogs } from "src/hooks/useLogs";
import { systemModules } from "src/utils/selectors";
import {
  SNACK_ACTION_TYPE,
  SNACKINFO_INITIAL_STATE,
  snackInfoReducer,
} from "src/reducers/snackInfoReducer";
import constants from "src/constants/constants";
import MissingPeriod from "src/pages/MissingPeriod";

const {
  PAGES: {
    DISTRIBUTION,
    MODULES: { EXCESS },
    DISPLAY_NAME: { EXCESS: EXCESS_DISPLAY },
  },
  QUERIES: {
    INPUT: {
      LOG_TYPE: { CLOSE_DISTRIBUTION },
    },
    CODE: { VALIDATION_FAILED },
  },
  SNACKBAR_MESSAGE: {
    DISTRIBUTION: {
      EXCESS_UPLOAD_ERROR,
      EXCESS_UPLOAD_SUCCESS,
      EXCESS_UPLOAD_WARNING,
      PERIOD_STATUS: { IN_PROGRESS },
    },
  },
} = constants;
const {
  CLOSE_SNACK_INFO,
  SET_SNACK_ERROR,
  SET_SNACK_SUCCESS,
  SET_SNACK_WARNING,
} = SNACK_ACTION_TYPE;

const Excess = () => {
  const [refresh, setRefresh] = useState(false);
  const [valueTab, setValueTab] = useState(0);
  const location = useLocation();
  const period = location.state?.periodInfo.name || "";
  const isConfirmed = location.state?.periodInfo.distributionStatus === 3;
  const isClosingInProgress =
    location.state?.periodInfo.distributionStatus === 2;
  const [snackInfoState, snackInfoDispatch] = useReducer(
    snackInfoReducer,
    SNACKINFO_INITIAL_STATE
  );
  const [openLoad, setOpenLoad] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const { getLog, rows: rowsLogs, loading: loadingLogs } = useLogs();
  const getLatestUploadedFile = () => {
    getLog({
      logTypeId: systemModules(EXCESS),
      fkId: parseInt(period),
    });
  };
  const {
    getLog: getLogConfirm,
    rows: rowsLogsConfirm,
    loading: loadingLogsConfirm,
  } = useLogs();

  const [insertExcessJson] = useMutation(INSERT_JSON_SURPLUS, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        collectionPeriod: period,
      },
    },
    onError: (error: any) => {
      let { message, code } = error?.graphQLErrors[0] || {};
      //status detail should be obtained from a different field in the error object
      if (VALIDATION_FAILED === code) {
        snackInfoDispatch({
          type: SET_SNACK_ERROR,
          payload: message.split(".")[0],
        });
        refreshOnError(parseInt(message.split(":")[1]));
      } else {
        // SEQUELIZE_VALIDATION_FAILED
        snackInfoDispatch({
          type: SET_SNACK_ERROR,
          payload:
            message === "SequelizeUniqueConstraintError"
              ? EXCESS_UPLOAD_ERROR.UNIQUE_CONSTRAINT
              : message === "SequelizeForeignKeyConstraintError"
              ? EXCESS_UPLOAD_ERROR.FOREIGN_KEY_CONSTRAINT
              : EXCESS_UPLOAD_ERROR.DEFAULT,
        });
      }
      handleCloseLoading();
    },
  });

  const refreshOnError = (status: number) => {
    location.state.periodInfo.distributionStatus = status;
    setRefresh(true);
  };

  const [insertExcessFile] = useMutation(INSERT_FILE_SURPLUS, {
    fetchPolicy: "network-only",
    onError: () => {
      snackInfoDispatch({
        type: SET_SNACK_WARNING,
        payload: EXCESS_UPLOAD_WARNING,
      });
      handleCloseLoading();
    },
  });

  const handleInsertDB = async () => {
    if (period) {
      await insertExcessJson().then(async (res) => {
        if (res?.data?.period !== null && res?.data?.period !== undefined) {
          await insertExcessFile({
            variables: {
              input: {
                logId:
                  res.data.period.distribution.surplus.bulkCreate.data.logId,
              },
            },
          }).finally(() => {
            setRefresh(true);
            snackInfoDispatch({
              type: SET_SNACK_SUCCESS,
              payload: EXCESS_UPLOAD_SUCCESS,
            });
          });
        }
      });
      handleCloseLoading();
      getLatestUploadedFile();
    }
  };

  const handleCloseLoading = () => {
    setOpenLoad(false);
    setUploadInProgress(false);
  };

  useEffect(() => {
    if (valueTab === 0) {
      getLatestUploadedFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isConfirmed &&
      getLogConfirm({
        logTypeId: CLOSE_DISTRIBUTION,
        fkId: parseInt(period),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed]);

  if (!period) {
    return <MissingPeriod displayName={EXCESS_DISPLAY} />;
  }

  return (
    <>
      <AlertSnackbar        
        onClose={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        open={snackInfoState.open}
        status={snackInfoState.status}
        message={snackInfoState.message}
      />
      <Box pl="32px" pr="32px">
        <Typography variant="h1" pt="24px" mb="16px">
          {EXCESS_DISPLAY} - {getCurrentPeriod(period)[0]}
        </Typography>
        <Breadcrum
          back1="Inicio"
          link1="/"
          now={EXCESS_DISPLAY + " - " + getCurrentPeriod(period)[0]}
        />
        {!loadingLogsConfirm && isConfirmed && (
          <Alerts
            mensaje={[
              "Este periodo ha sido confirmado  ",
              rowsLogsConfirm[0]?.logDate
                ? "el " +
                  rowsLogsConfirm[0]?.logDate.slice(0, 10).split("-").join("/")
                : "sin fecha registrada",
            ]}
            open={true}
            data={null}
            setOpen={null}
            severidad={"info"}
            noClose
            typeAlert={2}
          />
        )}
        {isClosingInProgress && (
          <Alerts
            mensaje={IN_PROGRESS}
            open={true}
            data={null}
            setOpen={null}
            severidad={"warning"}
            noClose
            typeAlert={2}
          />
        )}
        <Box sx={filterBox} mt="16px" mb="24px">
          <Grid container>
            <Grid xs={9}>
              <Typography sx={littleTitle} component="div" display={"inline"}>
                Generar reporte
              </Typography>
              <Typography sx={detailText} component="div" pt="16px" pb="8px">
                Aquí puedes generar y descargar el reporte maestro acorde al
                periodo confirmado.
              </Typography>
            </Grid>
            <Grid
              xs={3}
              justifyContent={"center"}
              textAlign={"end"}
              alignSelf={"center"}
            >
              <ExportButton
                type={4}
                collectionPeriod={period}
                disabled={loadingLogsConfirm || !isConfirmed}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={filterBox} mt="16px" mb="24px">
          {loadingLogs ? (
            <SkeletonHeaderC />
          ) : (
            <Grid container>
              <Grid xs={7}>
                <Typography sx={littleTitle} component="div" display={"inline"}>
                  {EXCESS_DISPLAY}:
                </Typography>
                <Typography sx={titleLight} component="div" display={"inline"}>
                  {" "}
                  Carga mensual
                </Typography>
                <Typography sx={detailText} component="div" pt="16px" pb="8px">
                  Aquí puedes cargar los archivos del periodo actual para
                  visualizar y descargar un reporte.
                </Typography>
                <Typography sx={subText20} component="div">
                  {rowsLogs.length > 0
                    ? "Última carga realizada el " +
                      rowsLogs[0]?.logDate.slice(0, 10).replace(/-/g, "/") +
                      " a las " +
                      rowsLogs[0]?.logDate.slice(11, 16) +
                      " hrs: con un total de " +
                      rowsLogs[0]?.description +
                      " registros."
                    : "No se han realizado cargas"}
                </Typography>
              </Grid>
              <Grid xs={5} pt="36px">
                <Stack
                  direction="row"
                  textAlign="right"
                  justifyContent="flex-end"
                >
                  <Typography sx={littleDetail} component="div" pr="16px">
                    Formato aceptados: .xlsx <br></br>
                    Peso máximo de 4MB
                  </Typography>
                  <UploadButton
                    disabled={
                      isConfirmed ||
                      isClosingInProgress ||
                      !hasPermissions().uploadFile
                    }
                    accept=".xlsx"
                    maxSize={4}
                    onUpload={() => handleInsertDB()}
                    openLoad={openLoad}
                    setOpenLoad={setOpenLoad}
                    uploadInProgress={uploadInProgress}
                    setUploadInProgress={setUploadInProgress}
                    period={period}
                    module={EXCESS}
                  />
                </Stack>
              </Grid>
            </Grid>
          )}
        </Box>
        <Tabs
          sx={{ width: "1000px" }}
          textColor="primary"
          value={valueTab}
          onChange={(event, value) => setValueTab(value)}
        >
          <Tab label={EXCESS_DISPLAY} />
          <Tab label="Historial de Carga" sx={{ whiteSpace: "pre" }} />
          <Tab label="Registros eliminados" sx={{ whiteSpace: "pre" }} />
        </Tabs>
        <TabPanel value={valueTab} index={0}>
          <TableExcess
            refresh={refresh}
            setRefresh={setRefresh}
            period={period}
            module={DISTRIBUTION}
            isConfirmed={isConfirmed || isClosingInProgress}
            refreshOnError={refreshOnError}
            setSnackInfo={snackInfoDispatch}
            isActive={true}
          />
        </TabPanel>
        <TabPanel value={valueTab} index={1}>
          <RecordTableCash
            refresh={refresh}
            setRefresh={setRefresh}
            period={period}
            module={EXCESS}
            tabValue={valueTab}
          />
        </TabPanel>
        <TabPanel value={valueTab} index={2}>
          <TableExcess
            refresh={refresh}
            setRefresh={setRefresh}
            period={period}
            module={DISTRIBUTION}
            isConfirmed={isConfirmed || isClosingInProgress}
            setSnackInfo={snackInfoDispatch}
            isActive={false}
          />
        </TabPanel>
      </Box>
    </>
  );
};

export default Excess;
