import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  CustomInputSlim,
  CustomModal,
  CustomModalTitle,
  CustomSelect,
  DatePicker,
} from "src/components";
import { StyledTableCell } from "src/components/tables/tableStyle";
import {
  blackBold,
  littleDetail,
  littleTitle,
  modalSubtitle,
  purpleLittle,
  titleTextBox,
} from "src/components/typography/styles";
import TrashGreen from "src/assets/icons/trashGreen.svg";
import TrashGray from "src/assets/icons/trashGray.svg";
import { fillPeriodOptions } from "src/utils/arrayUtils";
import { convertUTCDateToLocalDate, createPeriodList, getCurrentPeriod } from "src/utils/dates";
import { useEffect, useState } from "react";
import { renderAmount, testNumber } from "src/utils/numberUtils";
import { SNACK_ACTION_TYPE } from "src/reducers/snackInfoReducer";
import constants from "src/constants/constants";
import moment from "moment";
import { iconStyle, style } from "src/components/alert/styles";
import { usePayment } from "../../hooks/usePayment";

const { SET_SNACK_ERROR, SET_SNACK_SUCCESS } = SNACK_ACTION_TYPE;
const {
  COMPONENTS: {
    BUTTONS: { CANCEL, SAVE },
  },
  DATE: { UPDATE_FORMAT },
  SNACKBAR_MESSAGE: {
    COLLECTION: { PAYMENTS_SPLIT_ERROR, PAYMENTS_SPLIT_SUCCESS },
  },
} = constants;

type SplitPaymentProps = {
  onOpen: boolean;
  handleClose: () => void;
  type: number; // 1: payment, 2: previred
  info: {
    id: string;
    amount: number;
    contributorName: string;
    contributorRut: string;
    paymentDate: string;
    paymentPeriod: string;
  };
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackInfo: React.Dispatch<React.SetStateAction<any>>;
};

type PaymentType = {
  paymentDate: string;
  paymentPeriod: string;
  amount: number;
};

const SplitPayment = ({
  onOpen,
  handleClose,
  type,
  info,
  setRefresh,
  setSnackInfo,
}: SplitPaymentProps) => {
  const {
    id,
    amount,
    contributorName,
    contributorRut,
    paymentDate,
    paymentPeriod,
  } = info;
  const [basePaymentAmount, setBasePaymentAmount] = useState<number>(0);
  const [basePaymentDate, setBasePaymentDate] = useState<any>(null);
  const [newPaymentArr, setNewPaymentArr] = useState<any>([]);
  const periodList = createPeriodList(true);
  const datePickerMinDate = convertUTCDateToLocalDate("01-01-2022");  

  const {
    splitPayment,
    splitPaymentLoading,
    splitPreviredPayment,
    splitPreviredLoading,
  } = usePayment();

  const handleSave = () => {
    const newPaymentsFormatted = newPaymentArr.map((payment: any) => {
      return {
        paymentDate: moment(payment.paymentDate).format(UPDATE_FORMAT),
        paymentPeriod: periodList[payment.paymentPeriod - 1],
        amount: payment.amount,
      };
    });
    type === 1 ? handleSavePayment(newPaymentsFormatted) : handleSavePreviredPayment(newPaymentsFormatted);
  }

  const handleSavePreviredPayment = (newPaymentsFormatted: any) => {
    splitPreviredPayment({
      variables: {
        input: {
          staticPaymentData: {
            id,
            amount: basePaymentAmount,
          },
          newPaymentsData: newPaymentsFormatted,
        },
      },
      onCompleted: () => {
        setRefresh(true);
        setSnackInfo({
          type: SET_SNACK_SUCCESS,
          payload: PAYMENTS_SPLIT_SUCCESS,
        });
        handleCloseModal();
      },
      onError: () => {
        setSnackInfo({
          type: SET_SNACK_ERROR,
          payload: PAYMENTS_SPLIT_ERROR,
        });
        handleCloseModal();
      },
    });

  }

  const handleSavePayment = (newPaymentsFormatted: any) => {
    splitPayment({
      variables: {
        input: {
          staticPaymentData: {
            id,
            amount: basePaymentAmount,
          },
          newPaymentsData: newPaymentsFormatted,
        },
      },
      onCompleted: () => {
        setRefresh(true);
        setSnackInfo({
          type: SET_SNACK_SUCCESS,
          payload: PAYMENTS_SPLIT_SUCCESS,
        });
        handleCloseModal();
      },
      onError: () => {
        setSnackInfo({
          type: SET_SNACK_ERROR,
          payload: PAYMENTS_SPLIT_ERROR,
        });
        handleCloseModal();
      },
    });
  };

  const currencySign = {
    startAdornment: <InputAdornment position="start">$</InputAdornment>,
  };

  const handleAddPaymentRow = () => {
    setNewPaymentArr([
      ...newPaymentArr,
      {
        paymentDate: "",
        paymentPeriod: "",
        amount: 0,
      },
    ]);
  };

  const handleBaseAmountChange = (value: string) => {
    if (testNumber(value)) {
      setBasePaymentAmount(parseInt(value));
    }
    if (value === "") {
      setBasePaymentAmount(0);
      return;
    }
  };

  const handleRowRemovePayment = (index: number) => () => {
    const newArr = newPaymentArr.filter((_: any, i: number) => i !== index);
    setNewPaymentArr(newArr);
  };

  const handleRowDateChange = (date: any, index: number) => {
    const newArr = [...newPaymentArr];
    newArr[index].paymentDate = date;
    setNewPaymentArr(newArr);
  };

  const handleRowPeriodChange = (e: any, index: number) => {
    const newArr = [...newPaymentArr];
    newArr[index].paymentPeriod = e.target.value;
    setNewPaymentArr(newArr);
  };

  const handleRowAmountChange = (value: string, index: number) => {
    const newArr = [...newPaymentArr];
    if (testNumber(value)) {
      newArr[index].amount = parseInt(value);
    }
    if (value === "") {
      newArr[index].amount = 0;
    }
    setNewPaymentArr(newArr);
  };

  const handleCloseModal = () => {
    handleClose();
    setNewPaymentArr([]);
  };

  const sumAmounts = newPaymentArr.reduce(
    (acc: number, payment: PaymentType) => acc + payment.amount,
    basePaymentAmount
  );
  const validateSum = () => {
    const alertMessage = `Monto pagado disponible: $${renderAmount(
      amount
    )}  |  Total monto a pagar: $${renderAmount(sumAmounts)}`;
    if (sumAmounts > amount) {
      return {
        style: "error",
        message:
          alertMessage +
          `  |  Monto excede saldo por: $${renderAmount(sumAmounts - amount)}`,
        valid: false,
      };
    } else if (sumAmounts < amount) {
      return {
        style: "error",
        message:
          alertMessage +
          `  |  Monto de saldo insuficiente por: $${renderAmount(
            amount - sumAmounts
          )}`,
        valid: false,
      };
    } else {
      return {
        style: "success",
        message: alertMessage,
        valid: true,
      };
    }
  };

  const severity = validateSum().style as any;
  const alertMessage = validateSum().message;

  const validateArrAmounts = () => {
    return newPaymentArr.every((payment: PaymentType) => payment.amount > 0);
  };

  const validateArrPeriods = () => {
    return newPaymentArr.every(
      (payment: PaymentType) => payment.paymentPeriod !== ""
    );
  }

  const validateArrDates = () => {
    return newPaymentArr.every(
      (payment: PaymentType) => payment.paymentDate !== "" && payment.paymentDate !== null
    );
  };

  const saveButtonDisabled = () => {
    return (
      !validateSum().valid ||   
      !validateArrPeriods()  ||
      !validateArrDates() ||
      !validateArrAmounts() ||
      newPaymentArr.length === 0
    );
  };

  useEffect(() => {
    if (onOpen) {
      setBasePaymentAmount(amount);
      const aux = paymentDate.slice(0, 10).split("/");
      setBasePaymentDate(convertUTCDateToLocalDate(aux[1] + "-" + aux[0] + "-" + aux[2]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOpen]);

  return (
    <>
      <CustomModal
        maxWidth="md"
        fullWidth
        open={onOpen}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <CustomModalTitle id="splitPayment" onClose={handleCloseModal}>
          <Typography sx={littleTitle} textAlign="center" pt="16px">
            Dividir pagos
          </Typography>
          <Typography sx={modalSubtitle} textAlign="center">
            Aquí puedes dividir pagos para diferentes periodos de remuneración.
          </Typography>
        </CustomModalTitle>
        <DialogContent dividers>
          <Grid container spacing={2} pb="12px">
            <Grid item xs={5}>
              <Typography sx={purpleLittle} pb="4px">
                Nombre y RUT del cliente
              </Typography>
              <Typography sx={blackBold} pt="4px">
                {contributorName ?? "N/D"} {contributorRut}
              </Typography>
            </Grid>
            <Grid item xs={3.5}>
              <Typography sx={purpleLittle} pb="4px">
                Período remuneración
              </Typography>
              <Typography sx={blackBold} pt="4px">
                {getCurrentPeriod(paymentPeriod)[0]}
              </Typography>
            </Grid>
            <Grid item xs={3.5}>
              <Typography sx={purpleLittle} pb="4px">
                Monto pagado
              </Typography>
              <Typography sx={blackBold} pt="4px">
                ${renderAmount(amount)}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container>
            <Grid item xs={9}>
              <Typography sx={titleTextBox} pt="12px">
                Pagos
              </Typography>
              <Typography sx={littleDetail} pt="6px">
                Aquí puedes agregar la información para dividir pagos
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                sx={{ mt: "16px", width: "100%" }}
                onClick={handleAddPaymentRow}
                disabled={splitPaymentLoading || splitPreviredLoading}
              >
                Agregar pago
              </Button>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <Box sx={{ pt: "16px", width: "96%" }}>
              <Alert
                severity={severity}
                sx={style(severity)}
                iconMapping={iconStyle(severity)}
              >
                {alertMessage}
              </Alert>
            </Box>
          </Grid>
          <TableContainer
            className="grayScroll"
            component={Paper}
            sx={{
              boxShadow: "none",
              borderRadius: "8px",
              border: "0.5px solid #D2D6D9",
              maxHeight: "290px",
              maxWidth: "842px",
              mt: "24px",
            }}
          >
            <Table aria-label="simple table" stickyHeader>
              <TableHead className="color-head-table">
                <TableRow>
                  <StyledTableCell sx={{ width: "168px" }}>
                    Fecha de pago
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: "168px" }}>
                    Período de remuneración
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: "168px" }}>
                    Monto a pagar
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: "68px", textAlign: "center" }}>
                    Eliminar
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <DatePicker value={basePaymentDate} slim disabled label={""} />
                  </TableCell>
                  <TableCell>
                    <CustomSelect
                      slim
                      value={1}
                      data={fillPeriodOptions([paymentPeriod])}
                      disabled
                    />
                  </TableCell>
                  <TableCell>
                    <CustomInputSlim
                      fullWidth
                      value={basePaymentAmount}
                      InputProps={currencySign}
                      onChange={(e) => handleBaseAmountChange(e.target.value)}
                      inputProps={{ "data-testid": "baseAmount" }}
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <IconButton disabled>
                      <img src={TrashGray} alt="" />
                    </IconButton>
                  </TableCell>
                </TableRow>
                {newPaymentArr.length > 0 &&
                  newPaymentArr.map((payment: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>
                        <DatePicker
                          slim
                          value={payment.paymentDate}
                          onChange={(date: any) =>
                            handleRowDateChange(date, index)
                          }
                          label={""}
                          minDate={datePickerMinDate}
                          maxDate={new Date()}
                          name="paymentDate"
                          testid="rowDate"
                        />
                      </TableCell>
                      <TableCell>
                        <CustomSelect
                          slim
                          data={fillPeriodOptions(periodList)}
                          value={payment.paymentPeriod}
                          onChange={(e: any) => handleRowPeriodChange(e, index)}
                          titulo={"Seleccione"}
                          hideTitle={true}
                          name="paymentPeriod"
                        />
                      </TableCell>
                      <TableCell>
                        <CustomInputSlim
                          fullWidth
                          value={payment.amount}
                          InputProps={currencySign}
                          onChange={(e) =>
                            handleRowAmountChange(e.target.value, index)
                          }
                          name="amount"
                          inputProps={{ "data-testid": "rowAmount" }}
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <IconButton
                          onClick={handleRowRemovePayment(index)}
                          data-testid="removeRow"
                        >
                          <img src={TrashGreen} alt="Remover fila" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={"16px"}
          >
            <Button
              onClick={handleCloseModal}
              color="secondary"
              sx={{ width: "150px" }}
            >
              {CANCEL}
            </Button>
            {(splitPaymentLoading || splitPreviredLoading) ? (
              <Button color="primary" sx={{ width: "150px" }}>
                <div className="spinnerButton" />
              </Button>
            ) : (
              <Button
                sx={{ width: "150px" }}
                disabled={saveButtonDisabled()}
                onClick={() => handleSave()}
              >
                {SAVE}
              </Button>
            )}
          </Stack>
        </DialogActions>
      </CustomModal>
    </>
  );
};

export default SplitPayment;
