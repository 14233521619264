import { useEffect, useState } from "react";
import { createPeriodList, getCurrentPeriod } from "src/utils/dates";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import SearchCredits from "./searchCredits";
import { ChipsCredits } from "./chipsCredits";
import { NoResult } from "src/components/tables/noResult";
import { StyledTableCell } from "src/components/tables/tableStyle";
import { paginatorTheme } from "src/components/paginator/paginatorStyle";
import { boxNoPadding2 } from "src/components/box/styles";
import {
  contTitle,
  subTable,
  textTooltip,
} from "src/components/typography/styles";
import { SkeletonTableCredits } from "./skeletonsCredits";
import Info from "src/assets/icons/info.svg";
import { BootstrapTooltip } from "src/components/header/drawerLeft";
import EyeGreen from "../../../assets/icons/eyeGreen.svg";
import AssociatedPayments from "./associatedPayments";
import ExportButton from "src/components/buttons/ExportButton";
import constants from "src/constants/constants";
import { useContract } from "../hooks/useContract";

const {
  COMPONENTS: {
    BUTTONS: { FILTERS },
  },
} = constants;

const TableCredits = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalBuscar, setModalBuscar] = useState(false);
  const [viewPayments, setViewPayments] = useState(false);
  const [rowData, setRowData] = useState({
    contributorRut: "",
    contributorName: "",
    contractedAmount: 0,
    paymentPeriod: "",
  });

  const [filters, setFilters] = useState({
    contributorRut: null,
    paymentPeriod: null,
    statusId: null,
    workerType: null,
    typeId: null,
  });

  const {
    getContractData,
    contractCount,
    contractRows,
    contractLoading,
    contractError,
  } = useContract();

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleDetails = (elem: any) => {    
    setRowData({
      contributorRut: elem.contributorRut,
      contributorName: elem.contributorName,
      contractedAmount: elem.contractedAmount,
      paymentPeriod: elem.paymentPeriod,
    });
    setViewPayments(true);
  };

  const TipoTrabajador = () => {
    return (
      <>
        <Typography sx={textTooltip}>D: Dependiente</Typography>
        <Typography sx={textTooltip}>P: Pensionado</Typography>
        <Typography sx={textTooltip}>V: Voluntario</Typography>
        <Typography sx={textTooltip}>I: Independiente</Typography>
      </>
    );
  };

  const TagsCollection = ({ estado }: any) => {
    switch (true) {
      case estado === "Al día":
        return <label className="tagEstadoCasos tagGGreen">Al día</label>;
      case estado === "Atrasada":
        return <label className="tagEstadoCasos tagPurple">Atrasada</label>;
      case estado === "Pendiente pago":
        return <label className="tagEstadoCasos tagYellow">Pendiente</label>;
      case estado === "Pagada":
        return <label className="tagEstadoCasos tagGreen">Pagada</label>;
      default:
        return <label className="tagEstadoCasos tagRed">{estado}</label>;
    }
  };

  useEffect(() => {
    getContractData({
      ...filters,
      isActive: true,
      isConsolidated: true,
      limit: rowsPerPage,
      offset: (page - 1) * rowsPerPage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPage(1);
    getContractData({
      ...filters,
      isActive: true,
      isConsolidated: true,
      limit: rowsPerPage,
      offset: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    getContractData({
      ...filters,
      isActive: true,
      isConsolidated: true,
      limit: rowsPerPage,
      offset: (page - 1) * rowsPerPage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  return (
    <Box sx={boxNoPadding2} mt="16px" mb="24px">
      <AssociatedPayments
        onOpen={viewPayments}
        handleCloseModal={() => setViewPayments(false)}
        info={rowData}
      />
      {contractLoading ? (
        <SkeletonTableCredits
          columns={7}
          rows={3}
          data-testid="skeleton-table"
        />
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                pt="15px"
                pr="24px"
              >
                <Typography sx={contTitle} pt="11px" pl="24px">
                  Recaudación
                </Typography>{" "}
                <Stack direction={"row"}>
                  <div>
                    <Button
                      data-testid="ver-filtros"
                      color="inherit"
                      onClick={() => setModalBuscar(true)}
                      disabled={contractLoading || contractError ? true : false}
                    >
                      {FILTERS}
                    </Button>
                  </div>
                  <ExportButton
                    type={1}
                    collection={true}
                    isActive={true}
                    period={null}
                    contributorRut={filters.contributorRut || null}
                    paymentPeriod={filters.paymentPeriod || null}
                    statusId={filters.statusId}
                    workerType={filters.workerType}
                    typeId={filters.typeId}
                    consolidated={true}
                    disabled={contractCount === 0}
                  />
                </Stack>
              </Stack>
            </Grid>
            <ChipsCredits filtros={filters} setFiltros={setFilters} />
          </Grid>
          <>
            <SearchCredits
              onOpen={modalBuscar}
              handleClose={() => setModalBuscar(false)}
              filters={filters}
              setFilters={setFilters}
              options={createPeriodList(false)}
              disabled={contractLoading || contractError}
              data-testid="search-modal"
            />

            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "none",
                borderRadius: "none",
                paddingTop: "17px",
                borderBottom: "none",
              }}
            >
              {contractError ? (
                <NoResult
                  titulo={"No se pudo cargar la tabla"}
                  subtitulo={
                    "No fue posible mostrar la información debido a un problema de conexión. Intenta recargar la página para solucionar el problema."
                  }
                  error
                />
              ) : (
                <>
                  {contractCount === 0 ? (
                    filters.contributorRut ||
                    filters.paymentPeriod ||
                    filters.statusId ||
                    filters.workerType ||
                    filters.typeId ? (
                      <NoResult
                        titulo={"Sin Resultados"}
                        subtitulo={
                          "No se encontraron resultados para esta tabla"
                        }
                      />
                    ) : (
                      <NoResult
                        titulo={"No se han cargado archivos de acreencias"}
                        subtitulo={"Cargue información para ver el listado"}
                        dontSearch
                      />
                    )
                  ) : (
                    <>
                      <Table
                        sx={{ minWidth: 650 }}
                        aria-label="customized table"
                      >
                        <TableHead className="color-head-table">
                          <TableRow>
                            <StyledTableCell sx={{ minWidth: "114px" }}>
                              RUT y <br></br> Nombre <br></br> Cliente
                            </StyledTableCell>
                            <StyledTableCell sx={{ minWidth: "134px" }}>
                              Periodo venta
                            </StyledTableCell>
                            <StyledTableCell sx={{ minWidth: "134px" }}>
                              Periodo <br></br> remuneración
                            </StyledTableCell>
                            <StyledTableCell sx={{ minWidth: "134px" }}>
                              Periodo <br></br> Recaudación
                            </StyledTableCell>
                            <StyledTableCell sx={{ minWidth: "134px" }}>
                              Prima UF
                            </StyledTableCell>
                            <StyledTableCell sx={{ minWidth: "134px" }}>
                              Pactado <br></br> Pesos
                            </StyledTableCell>
                            <StyledTableCell sx={{ minWidth: "113px" }}>
                              Total Pagado
                            </StyledTableCell>
                            <StyledTableCell sx={{ minWidth: "124px" }}>
                              <Stack
                                direction="row"
                                justifyContent="space-evenly"
                              >
                                Tipo <br></br> trabajador{" "}
                                <BootstrapTooltip
                                  title={<TipoTrabajador />}
                                  arrow
                                  placement="right"
                                >
                                  <img src={Info} alt="info" />
                                </BootstrapTooltip>
                              </Stack>
                            </StyledTableCell>
                            <StyledTableCell sx={{ minWidth: "142px" }}>
                              Tipo <br></br> Acreencia
                            </StyledTableCell>
                            <StyledTableCell sx={{ minWidth: "142px" }}>
                              Estado
                            </StyledTableCell>
                            <StyledTableCell
                              className="headerTableAcciones"
                              align="center"
                              sx={{ minWidth: "120px" }}
                            >
                              Ver pagos<br></br>
                              Asociados
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        {contractRows.map((elem: any) => {
                          return (
                            <TableBody>
                              <TableRow data-testid="withData" key={elem.id}>
                                <TableCell>
                                  <Typography data-testid="rut" variant="h5">
                                    {elem.contributorRut}
                                  </Typography>
                                  <Typography sx={subTable}>
                                    {elem.contributorName}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="h5">
                                    {getCurrentPeriod(elem.sellPeriod)[0]}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="h5">
                                    {getCurrentPeriod(elem.paymentPeriod)[0]}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="h5">
                                    {getCurrentPeriod(elem.collectionPeriod)[0]}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="h5">
                                    {(
                                      Math.trunc(elem.paymentUf * 1000) / 1000
                                    ).toLocaleString("ES-CL")}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="h5">
                                    $
                                    {elem.contractedAmount.toLocaleString(
                                      "es-cl"
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="h5">
                                    $
                                    {elem.totalPaidAmount
                                      ? elem.totalPaidAmount.toLocaleString(
                                          "es-cl"
                                        )
                                      : 0}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  <Typography variant="h5">
                                    {elem.workerType}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <TagsCollection estado={elem.typeName} />
                                </TableCell>
                                <TableCell>
                                  <TagsCollection estado={elem.statusName} />
                                </TableCell>

                                <TableCell
                                  className="tableBodyAsignar"
                                  align="center"
                                >
                                  <Button
                                    color="secondary"
                                    onClick={() => handleDetails(elem)}
                                    data-testid="view-details-button"
                                  >
                                    <img src={EyeGreen} alt="Ver detalles" />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          );
                        })}
                      </Table>
                    </>
                  )}
                </>
              )}
            </TableContainer>
            {contractCount !== 0 && (
              <Grid alignItems="center" pb="2px" >
                <ThemeProvider theme={paginatorTheme}>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50]}
                    component="div"
                    count={contractCount}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={"Mostrar por página"}
                    sx={{ mt: "24px", mb: "24px" }}
                    labelDisplayedRows={(e) => {
                      return (
                        "" +
                        (page) +
                        " de " +
                        Math.ceil(contractCount / rowsPerPage)
                      );
                    }}
                  />
                </ThemeProvider>
              </Grid>
            )}
          </>
        </>
      )}
    </Box>
  );
};

export default TableCredits;
