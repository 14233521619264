import { getCurrentPeriod } from "./dates";

export const findOption = (options: any, value: any) => {
  let opt = null;
  options.forEach((element: any, index: number) => {
    if (value === element) {
      opt = index + 1;
    }
  });
  return opt;
};

export const fillPeriodOptions = (data: Array<string>) => {
  return data.map((element: string) => {
    return {
      nombre: getCurrentPeriod(element)[0],
    };
  });
};