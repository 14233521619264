import "regenerator-runtime"; // This import is necessary to run ExcelJS because avoid the CSP (content security policy) error
import { Workbook } from "exceljs";
import { getDateFromExcel } from "./dates";
import { runDvToUpperCase } from "./stringUtils";
import constants from "src/constants/constants";

let invalidCellValue = 0;
let errorMessage = "";

const {
  PAGES: {
    DEVOLUTION,
    MODULES: { EXCESS, OVERAGE, CREDITS },
  },
  FILE: {
    VALIDATION: { DEFAULT_ROW_LIMIT, PAYROLL_ROW_LIMIT },
  },
} = constants;

const checkData = (data: any) => {
  if (typeof data === "object" && data !== null) {
    invalidCellValue++;
    return false;
  }
  if (typeof data === "string") {
    return data !== null && data.trim() !== "";
  } else {
    return data !== null;
  }
};

const checkNumberData = (data: any, isPositiveCheck: boolean) => {
  if (typeof data === "number") {
    if (isPositiveCheck) {
      data <= 0 && invalidCellValue++;
      return data > 0;
    } else {
      data < 0 && invalidCellValue++;
      return data >= 0;
    }
  } else {
    invalidCellValue++;
    return false;
  }
};

const checkRowsLimit = (data: any, limit: number) => {
  if (data.length > limit) {
    return {
      error: `El archivo supera el límite de ${limit} filas para este proceso.`,
    };
  }
  return { error: null };
};

const checkInvalidData = () => {
  invalidCellValue > 0
    ? (errorMessage = constants.READ_FILE_MESSAGE.ERROR.INVALID_DATA) &&
      (invalidCellValue = 0)
    : (errorMessage = constants.READ_FILE_MESSAGE.ERROR.INCOMPLETE_DATA);
  return {
    data: null,
    error: constants.READ_FILE_MESSAGE.ERROR.DEFAULT + errorMessage,
  };
};

const checkEmptyRow = (data: any) => {
  return Object.values(data).every(
    (value) => value === null || isNaN(value as number)
  );
};

const checkError = (error: any) => {
  if (error.message === constants.FILE.VALIDATION.EXCEL_COLUMNS.OUT_OF_BOUNDS) {
    return {
      data: null,
      error:
        "Columnas del archivo no coinciden con el formato esperado. Verifique el nombre de las columnas.",
    };
  } else {
    //debug
    console.log(error);
    return {
      data: null,
      error: constants.READ_FILE_MESSAGE.ERROR.DEFAULT,
    };
  }
};

export const readFile = (file: any, module: string) => {
  switch (module) {
    case CREDITS:
      return readContract(file);
    case EXCESS:
      return readExcessExcel(file);
    case OVERAGE:
      return readOverageExcel(file);
    case DEVOLUTION:
      return readDevolutionExcel(file);
    default:
      return {
        data: null,
        error: constants.READ_FILE_MESSAGE.ERROR.DEFAULT,
      };
  }
};

export const initializeWorkbookSheetRow = async (file: any) => {
  const workbook = new Workbook();
  await workbook.xlsx.load(file);
  const sheet = workbook.worksheets[0];
  sheet.getRow(1).eachCell((cell, colNumber) => {
    sheet.getColumn(colNumber).key = cell.text;
  });
  const rows = sheet.getRows(2, sheet.rowCount - 1);
  return {
    rows,
  };
};

export const readContract = async (file: any) => {
  const { rows } = await initializeWorkbookSheetRow(file);
  if (rows === null || rows === undefined) {
    return {
      data: null,
      error:
        constants.READ_FILE_MESSAGE.ERROR.DEFAULT +
        constants.READ_FILE_MESSAGE.ERROR.INVALID_STRUCTURE,
    };
  }
  const { error: errorCheckRowsLimit } = checkRowsLimit(
    rows,
    DEFAULT_ROW_LIMIT
  );
  if (errorCheckRowsLimit === null) {
    try {
      const data = rows?.map((row) => {
        return {
          rut: runDvToUpperCase(row.getCell("RUT Cliente").value as string),
          sellPeriod: row.getCell("Periodo Venta").value,
          paymentPeriod: row.getCell("Periodo Remuneracion").value,
          paymentUF: row.getCell("Prima UF").value,
          workerType: row.getCell("Tipo de Trabajador Especifico").value,
          planType: row.getCell("Tipo de Plan").value,
          name: row.getCell("Nombre").value,
          contractPrice: row.getCell("Precio base del plan contratado").value,
          riskFactor: row.getCell("Suma de factores de riesgo").value,
          gesPrice: row.getCell("Precio GES").value,
          additionalBenefits: row.getCell("Precio beneficio adicional").value,
          complementaryPlanPrice: row.getCell("Precio Plan Complementario")
            .value,
        };
      });
      const nonNullData = data?.filter(
        (item: any) =>
          checkData(item.rut) &&
          checkNumberData(item.sellPeriod, true) &&
          checkNumberData(item.paymentPeriod, true) &&
          checkData(item.paymentUF) &&
          checkData(item.planType) &&
          checkData(item.name) &&
          checkNumberData(item.contractPrice, true) &&
          checkNumberData(item.riskFactor, true) &&
          checkNumberData(item.gesPrice, true) &&
          checkNumberData(item.additionalBenefits, false) &&
          checkNumberData(item.complementaryPlanPrice, true)
      );
      if (nonNullData?.length === data?.length) {
        return {
          data: nonNullData,
          error: null,
        };
      } else {
        return checkInvalidData();
      }
    } catch (error: any) {
      return checkError(error);
    }
  } else {
    return {
      data: null,
      error: errorCheckRowsLimit,
    };
  }
};

export const readPaymentsExcel = async (file: any) => {
  const { rows } = await initializeWorkbookSheetRow(file);
  if (rows === null || rows === undefined) {
    return {
      data: null,
      error:
        constants.READ_FILE_MESSAGE.ERROR.DEFAULT +
        constants.READ_FILE_MESSAGE.ERROR.INVALID_STRUCTURE,
    };
  }
  const { error: errorCheckRowsLimit } = checkRowsLimit(
    rows,
    DEFAULT_ROW_LIMIT
  );
  if (errorCheckRowsLimit === null) {
    try {
      const data = rows?.map((row) => {
        return {
          contributor_rut: runDvToUpperCase(row.getCell(1).value as string),
          payer_rut: runDvToUpperCase(row.getCell(2).value as string),
          payer_name: row.getCell(3).value,
          worker_type: row.getCell(4).value,
          payment_period: row.getCell(5).value,
          amount: row.getCell(6).value,
          taxable_seven_percent: row.getCell(7).value,
          additional_amount: row.getCell(8).value,
          taxable_salary: row.getCell(9).value,
          payment_type: row.getCell(10).value,
          payment_date: getDateFromExcel(row.getCell(11).value),
        };
      });
      const nonNullData = data?.filter(
        (item: any) =>
          checkData(item.contributor_rut) &&
          checkData(item.payer_rut) &&
          checkData(item.payer_name) &&
          checkData(item.worker_type) &&
          checkData(item.payment_period) &&
          checkData(item.amount) &&
          checkData(item.payment_type) &&
          checkData(item.payment_date)
      );
      if (nonNullData?.length === data?.length) {
        return {
          data: data,
          error: null,
        };
      } else {
        return checkInvalidData();
      }
    } catch (error) {
      //debug
      console.log(error);
      return {
        data: null,
        error: constants.READ_FILE_MESSAGE.ERROR.DEFAULT,
      };
    }
  } else {
    return {
      data: null,
      error: errorCheckRowsLimit,
    };
  }
};

export const readExcessExcel = async (file: any) => {
  const { rows } = await initializeWorkbookSheetRow(file);
  if (rows === null || rows === undefined) {
    return {
      data: null,
      error:
        constants.READ_FILE_MESSAGE.ERROR.DEFAULT +
        constants.READ_FILE_MESSAGE.ERROR.INVALID_STRUCTURE,
    };
  }
  const { error: errorCheckRowsLimit } = checkRowsLimit(
    rows,
    DEFAULT_ROW_LIMIT
  );
  if (errorCheckRowsLimit === null) {
    try {
      const data = rows?.map((row) => {
        return {
          payment_period: row.getCell(constants.FILE.COLUMNS.PAYMENT_PERIOD)
            .value,
          contributor_rut: runDvToUpperCase(
            row.getCell(constants.FILE.COLUMNS.CONTRIBUTOR_RUT).value as string
          ),
          amount_type: row.getCell(constants.FILE.COLUMNS.AMOUNT_TYPE).value,
          movement_type: row.getCell(constants.FILE.COLUMNS.MOVEMENT_TYPE)
            .value,
          amount: row.getCell(constants.FILE.COLUMNS.AMOUNT).value,
        };
      });
      const nonNullData = data?.filter(
        (item: any) =>
          checkData(item.payment_period) &&
          checkData(item.contributor_rut) &&
          checkData(item.amount_type) &&
          checkData(item.movement_type) &&
          checkData(item.amount)
      );
      if (nonNullData?.length === data?.length) {
        return { data: nonNullData, error: null };
      } else {
        return checkInvalidData();
      }
    } catch (error) {
      //debug
      console.log(error);
      return {
        data: null,
        error: constants.READ_FILE_MESSAGE.ERROR.INVALID_STRUCTURE,
      };
    }
  } else {
    return {
      data: null,
      error: errorCheckRowsLimit,
    };
  }
};

export const readOverageExcel = async (file: any) => {
  const { rows } = await initializeWorkbookSheetRow(file);
  if (rows === null || rows === undefined) {
    return {
      data: null,
      error:
        constants.READ_FILE_MESSAGE.ERROR.DEFAULT +
        constants.READ_FILE_MESSAGE.ERROR.INVALID_STRUCTURE,
    };
  }
  const { error: errorCheckRowsLimit } = checkRowsLimit(
    rows,
    DEFAULT_ROW_LIMIT
  );
  if (errorCheckRowsLimit === null) {
    try {
      const data = rows?.map((row) => {
        return {
          payment_period: row.getCell(constants.FILE.COLUMNS.PAYMENT_PERIOD)
            .value,
          contributor_rut: runDvToUpperCase(
            row.getCell(constants.FILE.COLUMNS.CONTRIBUTOR_RUT).value as string
          ),
          amount: row.getCell(constants.FILE.COLUMNS.AMOUNT).value,
        };
      });

      // Removed Check for unique period & rut, could be added again if needed
      // const uniqueCombination = new Set<string>();
      // const uniqueContributors = data?.reduce((acc: any, item: any) => {
      //   const key = `${item.payment_period}-${item.contributor_rut}`;
      //   if (!uniqueCombination.has(key)) {
      //     uniqueCombination.add(key);
      //     acc.push(item.contributor_rut);
      //   }
      //   return acc;
      // }, []);
      // if (data.length !== uniqueContributors.length) {
      //   return {
      //     data: null,
      //     error:
      //       constants.READ_FILE_MESSAGE.ERROR.DEFAULT +
      //       constants.READ_FILE_MESSAGE.ERROR.DUPLICATED_CONTRIBUTOR,
      //   };
      // }

      const nonNullData = data?.filter(
        (item: any) =>
          checkData(item.payment_period) &&
          checkData(item.contributor_rut) &&
          Number.isInteger(item.amount) &&
          item.amount > 0
      );
      if (nonNullData?.length === data?.length) {
        return { data: nonNullData, error: null };
      } else {
        return checkInvalidData();
      }
    } catch (error: any) {
      if (
        error.message === constants.FILE.VALIDATION.EXCEL_COLUMNS.OUT_OF_BOUNDS
      ) {
        return {
          data: null,
          error: constants.READ_FILE_MESSAGE.ERROR.COLUMNS_FORMAT,
        };
      }
      //debug
      console.log(error);
      return {
        data: null,
        error: constants.READ_FILE_MESSAGE.ERROR.INVALID_STRUCTURE,
      };
    }
  } else {
    return {
      data: null,
      error: errorCheckRowsLimit,
    };
  }
};

const cleanInteger = (value: any) => {
  return parseInt(value.toString().replace(/\./g, ""));
};

export const readDevolutionExcel = async (file: any) => {
  try {
    const { rows } = await initializeWorkbookSheetRow(file);
    if (rows === null || rows === undefined) {
      return {
        data: null,
        error:
          constants.READ_FILE_MESSAGE.ERROR.DEFAULT +
          constants.READ_FILE_MESSAGE.ERROR.INVALID_STRUCTURE,
      };
    }
    const { error: errorCheckRowsLimit } = checkRowsLimit(
      rows,
      PAYROLL_ROW_LIMIT
    );
    if (errorCheckRowsLimit === null) {
      try {
        const data = rows?.map((row) => {
          return {
            contributor_rut: row.getCell("Rut").value,
            amount: cleanInteger(row.getCell("Monto($)")),
            movement_date: row.getCell("Fecha de Pago").value,
            status_id: row.getCell("Estado").value,
          };
        });
        const nonNullData = data?.filter(
          (item: any) =>
            checkData(item.contributor_rut) &&
            checkData(item.movement_date) &&
            checkData(item.status_id) &&
            Number.isInteger(item.amount)
        );

        if (
          nonNullData?.length === data?.length ||
          checkEmptyRow(data[data?.length - 1])
        ) {
          return { data: nonNullData, error: null };
        } else {
          return checkInvalidData();
        }
      } catch (error: any) {
        if (
          error.message ===
          constants.FILE.VALIDATION.EXCEL_COLUMNS.OUT_OF_BOUNDS
        ) {
          return {
            data: null,
            error: constants.READ_FILE_MESSAGE.ERROR.COLUMNS_FORMAT,
          };
        }
        //debug suggested to check file structure
        console.log(error.message);
        return {
          data: null,
          error: constants.READ_FILE_MESSAGE.ERROR.INVALID_STRUCTURE,
        };
      }
    } else {
      return {
        data: null,
        error: errorCheckRowsLimit,
      };
    }
  } catch (error: any) {
    //debug suggested to check file structure (an error here suggests file contains an image)
    console.log(error.message);
    return {
      data: null,
      error: constants.READ_FILE_MESSAGE.ERROR.DEFAULT,
    };
  }
};
