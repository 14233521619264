import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import SearchPrevired from "./searchPrevired";
import { ChipsPrevired } from "./chipsPrevired";
import { MenuPrevired } from "./menuPrevired";
import { NoResult } from "src/components/tables/noResult";
import { StyledTableCell } from "src/components/tables/tableStyle";
import { paginatorTheme } from "src/components/paginator/paginatorStyle";
import { boxNoPadding } from "src/components/box/styles";
import { contTitle, subTable } from "src/components/typography/styles";
import download from "src/assets/icons/download.svg";
import { SkeletonTableP } from "./skeletonsPrevired";
import { usePayment } from "src/pages/collection/hooks/usePayment";
import { renderAmount } from "src/utils/numberUtils";
import { ChangeLogModal } from "src/components";
import SplitPayment from "../../Cash/components/SplitPayment";

type TablePreviredProps = {
  period: string;
  refresh: boolean;
  canUpdate: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackInfo: React.Dispatch<React.SetStateAction<any>>;
};

const TablePrevired = ({
  period,
  refresh,
  canUpdate,
  setRefresh,
  setSnackInfo,
}: TablePreviredProps) => {
  const [modalBuscar, setModalBuscar] = useState(false);
  const [filters, setFilters] = useState({
    contributorRut: null,
    payerRut: null,
    paymentDate: null,
  });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [modalHistory, setModalHistory] = useState<boolean>(false);
  const [modalSplit, setModalSplit] = useState<boolean>(false);
  const [rowData, setRowData] = useState({
    id: "",
    contributorRut: "",
    contributorName: "",
    payerRut: "",
    payerName: "",
    paymentDate: "",
    paymentPeriod: "",
    amount: 0,
    paymentType: "",
  });

  const {
    getPaymentData,
    paymentCount,
    paymentRows,
    paymentLoading,
    paymentError,
  } = usePayment();

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    getPaymentData({
      collectionPeriod: period,
      paymentType: "PREVIRED",
      isActive: true,
      limit: rowsPerPage,
      offset: page * rowsPerPage - rowsPerPage,
      ...filters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPaymentData({
      collectionPeriod: period,
      paymentType: "PREVIRED",
      isActive: true,
      limit: rowsPerPage,
      offset: page * rowsPerPage - rowsPerPage,
      ...filters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    setPage(1);
    getPaymentData({
      collectionPeriod: period,
      paymentType: "PREVIRED",
      isActive: true,
      limit: rowsPerPage,
      offset: 0,
      ...filters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (refresh === true) {
      setPage(1);
      getPaymentData({
        collectionPeriod: period,
        contributorRut: null,
        payerRut: null,
        paymentDate: null,
        paymentType: "PREVIRED",
        isActive: true,
        limit: rowsPerPage,
        offset: 0,
      });
      setRefresh(false);
      setFilters({
        contributorRut: null,
        payerRut: null,
        paymentDate: null,
      });
    }
    // eslint-disable-next-line
  }, [refresh]);

  return (
    <Box sx={boxNoPadding} mt="16px" mb="24px">
      <ChangeLogModal
        onOpen={modalHistory}
        onClose={() => setModalHistory(false)}
        id={rowData.id}
        logTypeId={22}
        data-testid="record-modal"
      />
      <SplitPayment
        onOpen={modalSplit}
        handleClose={() => setModalSplit(false)}
        type={2}
        info={rowData}
        setRefresh={setRefresh}
        setSnackInfo={setSnackInfo}
        data-testid="split-modal"
      />
      {paymentLoading ? (
        <SkeletonTableP data-testid="skeleton-table" rows={3} columns={8} />
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                pt="15px"
                pr="24px"
                pb="17px"
              >
                <Typography sx={contTitle} pt="11px" pl="24px">
                  Listado de acreencias
                </Typography>{" "}
                <Stack direction={"row"}>
                  <div>
                    <Button
                      data-testid="ver-filtros"
                      color="inherit"
                      onClick={() => setModalBuscar(true)}
                      disabled={paymentError ? true : false}
                    >
                      Ver filtros
                    </Button>
                  </div>
                  <Button
                    color={"secondary"}
                    startIcon={
                      <img
                        src={download}
                        style={{ paddingRight: "12px" }}
                        alt="download"
                      />
                    }
                    disabled
                  >
                    {" "}
                    Exportar
                  </Button>
                </Stack>
              </Stack>
            </Grid>
            <ChipsPrevired filtros={filters} setFiltros={setFilters} />
          </Grid>

          {paymentError ? (
            <NoResult
              titulo={"No se pudo cargar la tabla"}
              subtitulo={
                "No fue posible mostrar la información debido a un problema de conexión. Intenta recargar la página para solucionar el problema."
              }
              error
            />
          ) : (
            <>
              <SearchPrevired
                onOpen={modalBuscar}
                handleClose={() => setModalBuscar(false)}
                filtros={filters}
                setFiltros={setFilters}
                data-testid="search-modal"
              />
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "none",
                  borderRadius: "none",
                }}
              >
                {paymentCount === 0 ? (
                  filters.contributorRut === "" &&
                  filters.payerRut === "" &&
                  filters.paymentDate === "" ? (
                    <NoResult
                      titulo={"No se han cargado archivos"}
                      subtitulo={"Cargue información para ver el listado"}
                      dontSearch
                    />
                  ) : (
                    <NoResult
                      titulo={"Sin Resultados"}
                      subtitulo={"No se encontraron resultados para esta tabla"}
                    />
                  )
                ) : (
                  <>
                    <Table sx={{ minWidth: 650 }} aria-label="customized table">
                      <TableHead className="color-head-table">
                        <TableRow>
                          <StyledTableCell>
                            RUT y Nombre <br></br> Cliente
                          </StyledTableCell>
                          <StyledTableCell>
                            RUT y Nombre <br></br> Pagador
                          </StyledTableCell>
                          <StyledTableCell>
                            Cotización <br></br> a pagar
                          </StyledTableCell>
                          <StyledTableCell>Cotización 7%</StyledTableCell>
                          <StyledTableCell>
                            Cotización <br></br> adicional
                          </StyledTableCell>
                          <StyledTableCell>
                            R. Imponible <br></br> trabajador
                          </StyledTableCell>
                          <StyledTableCell>
                            Fecha de <br></br>
                            Pago
                          </StyledTableCell>
                          <StyledTableCell>Acciones</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {paymentRows.map((row: any) => {
                        return (
                          <TableBody>
                            <TableRow data-testid="withData">
                              <TableCell>
                                <Typography data-testid="rut" variant="h5">
                                  {row.contributorRut}
                                </Typography>
                                <Typography sx={subTable}>
                                  {row.contributorName}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography data-testid="rut" variant="h5">
                                  {row.payerRut}
                                </Typography>
                                <Typography sx={subTable}>
                                  {row.payerName}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  ${renderAmount(row.amount)}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  ${renderAmount(row.taxableSevenPercent)}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  ${renderAmount(row.additionalAmount)}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  ${renderAmount(row.taxableSalary)}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  {row.paymentDate
                                    ? row.paymentDate
                                        .slice(0, 10)
                                        .replace(/-/g, "/")
                                    : "Sin info"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <MenuPrevired
                                  info={row}
                                  setModalHistory={setModalHistory}
                                  setModalSplit={setModalSplit}
                                  setRowData={setRowData}
                                  canUpdate={canUpdate}
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      })}
                    </Table>
                    <Grid alignItems="center" pb="15px" pt="24px">
                      <ThemeProvider theme={paginatorTheme}>
                        <TablePagination
                          rowsPerPageOptions={[10, 20, 50]}
                          component="div"
                          count={paymentCount}
                          rowsPerPage={rowsPerPage}
                          page={page - 1}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          labelRowsPerPage={"Mostrar por página"}
                          sx={{ mt: "24px", mb: "24px" }}
                          labelDisplayedRows={(e) => {
                            return (
                              "" +
                              page +
                              " de " +
                              Math.ceil(paymentCount / rowsPerPage)
                            );
                          }}
                        />
                      </ThemeProvider>
                    </Grid>
                  </>
                )}
              </TableContainer>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default TablePrevired;
