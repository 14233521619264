import { useEffect, useReducer, useState } from "react";
import { Box, Typography, Button, Stack, Grid } from "@mui/material";
import { filterBox } from "src/components/box/styles";
import {
  Alerts,
  AlertSnackbar,
  Breadcrum,
  CustomSelect,
  WarningModalCustom,
} from "src/components";
import ExcedenteIcon from "../../assets/icons/exedentesIcon.svg";
import ExcesosIcon from "../../assets/icons/exesosIcon.svg";
import CheckIcon from "src/assets/icons/checkIcon.svg";
import CheckGray from "src/assets/icons/checkGray.svg";
import { useMutation } from "@apollo/client";
import { SkeletonHeaderC } from "../collection/credits/components/skeletonsC";
import { useNavigate } from "react-router-dom";
import { hasPermissions } from "src/utils/hasPermissions";
import { CLOSE_DISTRIBUTION_PROCESS } from "src/services/graphql/mutations";
import constants from "src/constants/constants";
import {
  titleMenuBox,
  warningGray,
  warningPurple,
} from "src/components/typography/styles";
import { getCurrentPeriod, getPreviousPeriod } from "src/utils/dates";
import { SkeletonConfirmProcess } from "./excess/components/skeletonExcess";
import { useLogs } from "src/hooks/useLogs";
import {
  SNACK_ACTION_TYPE,
  SNACKINFO_INITIAL_STATE,
  snackInfoReducer,
} from "src/reducers/snackInfoReducer";
import { usePeriod } from "src/hooks/usePeriod";

const {
  COMPONENTS: {
    ALERTS: { ERROR, INFO, WARNING },
    BUTTONS: { SELECT },
  },
  QUERIES: {
    INPUT: {
      LOG_TYPE: { CLOSE_DISTRIBUTION },
    },
    FETCH_POLICY: { NETWORK_ONLY },
  },
  SNACKBAR_MESSAGE: {
    DISTRIBUTION: {
      CONFIRM_PERIOD_SUCCESS,
      CONFIRM_PERIOD_ERROR,
      PERIOD_STATUS: {
        CONFIRMABLE,
        IN_PROGRESS,
        ERROR: ERROR_STATUS,
        CONFIRMED,
      },
    },
  },
} = constants;
const { CLOSE_SNACK_INFO, SET_SNACK_ERROR, SET_SNACK_SUCCESS } =
  SNACK_ACTION_TYPE;

type PeriodInfo = {
  name: string;
  collectionStatus: number;
  distributionStatus: number;
};

const MenuDistribution = () => {
  const [openWarning, setOpenWarning] = useState(false);
  const [snackInfoState, snackInfoDispatch] = useReducer(
    snackInfoReducer,
    SNACKINFO_INITIAL_STATE
  );
  const [period, setPeriod] = useState<any>(null);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [periodInfo, setPeriodInfo] = useState({
    name: "",
    collectionStatus: 0,
    distributionStatus: 0,
  } as PeriodInfo);
  let navigate = useNavigate();
  const [currentPeriod, setCurrentPeriod] = useState<string | null>("");
  const {
    allPeriodsByProcess,
    periodsByProcess,
    loadingByProcess,
    listPeriodNames,
    getPeriodDistributionStatus,
    hasSurplusAndExcess,
    distributionStatusId,
    previousDevolutionStatusId,
    loadingPeriodStatus,
  } = usePeriod({ processType: 2 });
  const periodDistributionStatus = () => {
    getPeriodDistributionStatus(periodInfo.name);
  };

  const { getLog, rows: rowsLogs, loading: loadingLogs } = useLogs();
  const getConfirmDate = (fkId: string) => {
    getLog({
      logTypeId: CLOSE_DISTRIBUTION,
      fkId: parseInt(fkId),
    });
  };

  const [confirmExcess, { loading: loadingConfirm }] = useMutation(
    CLOSE_DISTRIBUTION_PROCESS,
    {
      fetchPolicy: NETWORK_ONLY as any,
      variables: {
        input: {
          periodName: periodInfo.name,
        },
      },
      onCompleted: () => {
        snackInfoDispatch({
          type: SET_SNACK_SUCCESS,
          payload: CONFIRM_PERIOD_SUCCESS,
        });
        setOpenWarning(false);
        periodDistributionStatus();
        getConfirmDate(periodInfo.name);
        setPeriodInfo({ ...periodInfo, distributionStatus: 7 });
        allPeriodsByProcess();
      },
      onError: () => {
        snackInfoDispatch({
          type: SET_SNACK_ERROR,
          payload: CONFIRM_PERIOD_ERROR,
        });
        setOpenWarning(false);
      },
    }
  );

  const handleSelectedPeriod = (option: any) => {
    if (option) {
      setPeriodInfo({
        name: periodsByProcess[option - 1].name,
        collectionStatus: periodsByProcess[option - 1].collectionStatusId,
        distributionStatus: periodsByProcess[option - 1].distributionStatusId,
      });
      setSelectedPeriod(option);
      getConfirmDate(periodsByProcess[option - 1].name);
    }
  };

  const WarningModalContent = () => {
    return (
      <>
        <br></br>
        <Typography sx={warningGray}>
          Estás confirmando el periodo de <br></br>
          recaudación
        </Typography>{" "}
        <Typography sx={warningPurple}>{currentPeriod}</Typography>
        <br></br>{" "}
        <Typography sx={warningGray}>
          ¿deseas continuar con esta acción?
        </Typography>
      </>
    );
  };

  const nonConfirmablePeriod = () => {
    return Boolean(
      periodInfo.collectionStatus === 1 ||
        periodInfo.distributionStatus === 2 ||
        periodInfo.distributionStatus === 3 ||
        periodInfo.distributionStatus === 7 ||
        !hasSurplusAndExcess ||
        previousDevolutionStatusId === 1
    );
  };

  const confirmDisabled = () => {
    if (
      loadingByProcess ||
      !hasPermissions().closeProcess ||
      nonConfirmablePeriod()
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    allPeriodsByProcess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (periodInfo.name !== "") {
      periodDistributionStatus();
      setCurrentPeriod(getCurrentPeriod(periodInfo.name)[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodInfo.name]);

  const renderAlert = (message: string, severity: string) => (
    <Alerts
      mensaje={message}
      open={true}
      data={null}
      setOpen={null}
      severidad={severity}
      noClose
      typeAlert={2}
    />
  );

  return (
    <>
      <AlertSnackbar        
        onClose={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        open={snackInfoState.open}
        status={snackInfoState.status}
        message={snackInfoState.message}
      />
      <WarningModalCustom
        secondaryButton="Cancelar"
        primaryButton="Confirmar"
        contentText={<WarningModalContent />}
        title="Confirmar periodo"
        headWarning
        onOpen={openWarning}
        loading={loadingConfirm}
        onClose={() => setOpenWarning(false)}
        onConfirm={confirmExcess}
        loadingWidth={"120px"}
      />
      <Box pl="32px" pr="32px">
        <Typography variant="h1" pt="24px" mb="16px">
          Distribución
        </Typography>
        <Breadcrum back1="Inicio" link1="/" now="Distribución" />
        <Grid xs={12}>
          {periodInfo.distributionStatus === 3 && !loadingLogs && (
            <Alerts
              mensaje={[
                "Este periodo ha sido confirmado ",
                rowsLogs[0]?.logDate
                  ? "el " +
                    rowsLogs[0]?.logDate.slice(0, 10).split("-").join("/")
                  : "sin fecha registrada",
              ]}
              data={null}
              open={true}
              setOpen={null}
              severidad={INFO}
              noClose
              typeAlert={2}
            />
          )}
        </Grid>
        <Box sx={filterBox} mt="16px" mb="24px">
          {loadingByProcess ? (
            <SkeletonHeaderC />
          ) : (
            <Grid container>
              <Grid xs={7} pb="16px">
                <Typography variant="h2" component="div">
                  Dashboard Excedentes y Excesos
                </Typography>
                <Typography variant="h3" component="div">
                  Selecciona un periodo para ver la información
                  correspondientes.
                </Typography>
              </Grid>
              <Grid xs={5} pt="14px" alignItems="right">
                <Stack direction="row" alignItems="end">
                  <CustomSelect
                    onChange={(e: any) => setPeriod(e.target.value)}
                    value={period}
                    titulo={SELECT}
                    data={listPeriodNames(periodsByProcess)}
                    hideTitle
                  />
                  <Button
                    sx={{ ml: "16px", width: "150px" }}
                    onClick={() => handleSelectedPeriod(period)}
                    data-testid="btn-select"
                  >
                    {SELECT}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          )}
        </Box>
        <Grid container mt="16px">
          <Grid xs={6} pr="24px" textAlign={"center"}>
            <Box sx={filterBox} pt="30px !important">
              <img src={ExcedenteIcon} alt="" />
              <Typography pt="16px" pb="16px" sx={titleMenuBox}>
                Excedentes
              </Typography>
              {/* <Typography pt="12px" pb="16px" sx={subMenuBox}>
                subtítulo
              </Typography> */}
              <Button
                color="secondary"
                disabled={!selectedPeriod}
                onClick={() =>
                  navigate("excedentes", {
                    state: { periodInfo: periodInfo },
                  })
                }
              >
                Ir al módulo
              </Button>
            </Box>
          </Grid>
          <Grid xs={6} textAlign={"center"}>
            <Box sx={filterBox} pt="33px !important">
              <img src={ExcesosIcon} alt="" />
              <Typography pt="18px" pb="16px" sx={titleMenuBox}>
                Excesos
              </Typography>
              {/* <Typography pt="12px" pb="16px" sx={subMenuBox}>
                subtítulo
              </Typography> */}
              <Button
                color="secondary"
                disabled={!selectedPeriod}
                onClick={() =>
                  navigate("excesos", {
                    state: { periodInfo: periodInfo },
                  })
                }
              >
                Ir al módulo
              </Button>
            </Box>
          </Grid>
        </Grid>
        {
          {
            1: hasSurplusAndExcess && renderAlert(CONFIRMABLE, INFO),
            2: renderAlert(IN_PROGRESS, WARNING),
            4: renderAlert(ERROR_STATUS, ERROR),
            7: renderAlert(CONFIRMED, INFO),
          }[distributionStatusId as number]
        }
        {loadingPeriodStatus ? (
          <SkeletonConfirmProcess />
        ) : (
          <>
            <Box sx={filterBox} mt="24px" mb="24px">
              <Grid container>
                <Grid xs={9}>
                  <Typography variant="h2" component="div">
                    Confirmar periodo
                  </Typography>
                  <Typography variant="h3" component="div">
                    Para validar la información de un periodo ha de realizarse
                    la confirmación del mismo.
                  </Typography>
                </Grid>
                <Grid
                  xs={3}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Button
                    startIcon={
                      <img
                        src={confirmDisabled() ? CheckGray : CheckIcon}
                        alt=""
                      />
                    }
                    color="secondary"
                    onClick={() => setOpenWarning(true)}
                    disabled={confirmDisabled()}
                  >
                    Confirmar periodo
                  </Button>
                </Grid>
              </Grid>
              {distributionStatusId === 1 && nonConfirmablePeriod() && (
                <Grid container>
                  <Grid xs={12} pt="24px">
                    <Typography variant="h3" component="div">
                      Para confirmar el periodo de distribución {currentPeriod}:
                    </Typography>
                  </Grid>
                  <Grid xs={12}>
                    {periodInfo.collectionStatus === 1 &&
                      renderAlert(
                        `Debes cerrar el periodo de recaudación ${currentPeriod}`,
                        INFO
                      )}
                    {!hasSurplusAndExcess &&
                      renderAlert(
                        `Deben existir registros de excedentes y excesos para el periodo ${currentPeriod}`,
                        INFO
                      )}
                    {previousDevolutionStatusId === 1 &&
                      renderAlert(
                        `Debes confirmar la devolución del periodo ${
                          getPreviousPeriod(periodInfo.name as any)[0]
                        }`,
                        INFO
                      )}
                  </Grid>
                </Grid>
              )}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default MenuDistribution;
