import { useEffect, useReducer, useState } from "react";
import { GET_WORKER_TYPE } from "src/services/graphql/queries";
import { UPDATE_CONTRACT } from "src/services/graphql/mutations";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useCurrency } from "src/hooks/useCurrency";
import {
  CustomInput,
  CustomSelect,
  CustomModal,
  CustomModalTitle,
  SkeletonBar,
  AlertSnackbar,
} from "src/components";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import {
  blackBold,
  detailText,
  littleTitle,
  modalSubtitle,
  purpleLittle,
} from "src/components/typography/styles";
import { getCurrentPeriod } from "src/utils/dates";
import constants from "src/constants/constants";
import {
  SNACK_ACTION_TYPE,
  SNACKINFO_INITIAL_STATE,
  snackInfoReducer,
} from "src/reducers/snackInfoReducer";

const { CLOSE_SNACK_INFO, SET_SNACK_ERROR, SET_SNACK_SUCCESS } =
  SNACK_ACTION_TYPE;
const {
  COMPONENTS: {
    BUTTONS: { CANCEL, EDIT },
  },
  SNACKBAR_MESSAGE: { CREDITS_UPDATE_ERROR, CREDITS_UPDATE_SUCCESS },
} = constants;

type AddCreditsProps = {
  onOpen: boolean;
  handleClose: () => void;
  info: {
    id: number | string;
    paymentPeriod: string;
    paymentUf: number;
    workerType: string;
    contributorName: string;
    contributorRut: string;
    contractedAmount: number;
  };
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddCredits = ({
  onOpen,
  handleClose,
  info,
  setRefresh,
}: AddCreditsProps) => {
  const {
    id,
    contributorName,
    contributorRut,
    contractedAmount,
    paymentPeriod,
    paymentUf,
    workerType,
  } = info;
  const [inputUf, setInputUf] = useState("");
  const [inputAmount, setInputAmount] = useState<number | null>(null);
  const [workerTypeId, setWorkerTypeId] = useState("");
  const [workerArr, setWorkerArr] = useState<string[]>([]);
  const [ufValue, setUfValue] = useState(0);
  const [disableUpdate, setDisableUpdate] = useState(true);
  const [snackInfoState, snackInfoDispatch] = useReducer(
    snackInfoReducer,
    SNACKINFO_INITIAL_STATE
  );

  const { currencyInfo, oneBy, loading: loadingCurrency } = useCurrency();
  const getCurrencyInfo = () => {
    currencyInfo({
      currencyType: 1,
      periodName: paymentPeriod,
    });
  };

  const [getWorkerType] = useLazyQuery(GET_WORKER_TYPE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setWorkerArr(data?.period?.collection?.workerType?.all);
    },
  });

  const findWorkerTypeId = (str: string) => {
    let workerId = "";
    if (workerArr) {
      workerArr.forEach((item: any) => {
        if (item.smallName === str) {
          workerId = item.id;
        }
      });
    }
    setWorkerTypeId(workerId);
  };

  const findWorkerTypeSmallName = (id: any) => {
    let smallName = "";
    if (workerArr) {
      workerArr.forEach((item: any) => {
        if (item.id.toString() === id.toString()) {
          smallName = item.smallName;
        }
      });
    }
    return smallName;
  };

  const [updateCredit, { loading: loadingUpdate }] = useMutation(
    UPDATE_CONTRACT,
    {
      fetchPolicy: "network-only",
      variables: {
        input: {
          id: id as number,
          paymentUf: parseFloat(inputUf.replace(",", ".")),
          workerType: findWorkerTypeSmallName(workerTypeId),
        },
      },
      onCompleted: () => {
        setRefresh(true);
        snackInfoDispatch({
          type: SET_SNACK_SUCCESS,
          payload: CREDITS_UPDATE_SUCCESS,
        });
        handleCloseModal();
      },
      onError: () => {
        snackInfoDispatch({
          type: SET_SNACK_ERROR,
          payload: CREDITS_UPDATE_ERROR,
        });
        handleCloseModal();
      },
    }
  );

  const getOptions = () => {
    let optionsArray: any[] = [];
    if (workerArr) {
      workerArr.forEach((item: any) => {
        optionsArray.push(item);
      });
    }
    return optionsArray;
  };

  const handleCloseModal = () => {
    handleClose();
    setUfValue(0);
    setDisableUpdate(true);
    setInputAmount(null);
  };

  const handleWorkerTypeId = (id: string) => {
    // eslint-disable-next-line eqeqeq
    if (id != workerTypeId) {
      setWorkerTypeId(id);
      setDisableUpdate(false);
    }
  };

  const handleNumberChange = (value: string) => {
    // eslint-disable-next-line no-useless-escape
    const regex = /^(\d{1,2})(\,\d{0,3})?$/;
    value = value.replace(/^0+/, "");
    if (regex.test(value)) {
      setInputUf(value);
      setDisableUpdate(false);
    }
    if (value === "") {
      setInputUf("0");
      setDisableUpdate(true);
    }
  };

  useEffect(() => {
    getWorkerType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paymentUf) {
      setInputUf(paymentUf?.toFixed(3).replace(".", ","));
    }
    if (workerType) {
      findWorkerTypeId(workerType);
    }
    if (paymentPeriod) {
      getCurrencyInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  useEffect(() => {
    oneBy.currencyValue && setUfValue(oneBy.currencyValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneBy]);

  useEffect(() => {
    setInputAmount(
      parseFloat(inputUf.replace(",", ".")) * parseFloat(ufValue.toString())
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputUf]);

  return (
    <>
      <AlertSnackbar        
        onClose={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        open={snackInfoState.open}
        status={snackInfoState.status}
        message={snackInfoState.message}
      />
      <CustomModal
        maxWidth="md"
        open={onOpen}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <CustomModalTitle id="addCredits" onClose={handleCloseModal}>
          <Typography sx={littleTitle} textAlign="center" pt="32px">
            Edición para las acreencias
          </Typography>
          <Typography sx={modalSubtitle} textAlign="center">
            Modifica los campos necesarios para actualizar la información.
          </Typography>
        </CustomModalTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="column"
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid
              sx={{ margin: "0", width: "100%" }}
              container
              spacing={2}
              pb="24px"
            >
              <Grid item xs={6} pr="0 !important">
                <Typography sx={purpleLittle}>
                  Nombre y RUT del cliente
                </Typography>
                <Typography sx={blackBold} pt="4px">
                  {contributorName + " " + contributorRut}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={purpleLittle}>
                  Período de remuneración
                </Typography>
                <Typography sx={blackBold} pt="4px">
                  {getCurrentPeriod(paymentPeriod)[0]}
                </Typography>
              </Grid>
              <Grid item xs={6} pr="0 !important">
                <Typography sx={detailText} pb="6px">
                  Prima UF
                </Typography>
                <CustomInput
                  fullWidth
                  value={inputUf}
                  onChange={(e) => handleNumberChange(e.target.value)}
                  inputProps={{
                    "data-testid": "input-uf",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography sx={detailText} pb="6px">
                  Pactado Pesos
                </Typography>
                {loadingCurrency ? (
                  <SkeletonBar width={410} height={50} />
                ) : (
                  <CustomInput
                    fullWidth
                    value={
                      inputAmount ? inputAmount.toFixed(0) : contractedAmount
                    }
                    disabled
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12} pb="0 !important" pr="0 !important">
                <Typography sx={detailText} pb="6px">
                  Tipo de trabajador
                </Typography>
                <CustomSelect
                  value={workerTypeId}
                  hideTitle={true}
                  onChange={(e: any) => handleWorkerTypeId(e.target.value)}
                  data={getOptions().map(
                    (elem: any) => elem.smallName + " - " + elem.name
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              onClick={handleCloseModal}
              color="secondary"
              sx={{ width: "150px" }}
            >
              {CANCEL}
            </Button>
            {loadingUpdate ? (
              <Button
                color="primary"
                sx={{ height: "50px", minWidth: "150px" }}
              >
                <div className="spinnerButton"></div>
              </Button>
            ) : (
              <Button
                onClick={() => updateCredit()}
                sx={{ width: "150px" }}
                disabled={disableUpdate}
              >
                {EDIT}
              </Button>
            )}
          </Grid>
        </DialogActions>
      </CustomModal>
    </>
  );
};

export default AddCredits;
