import { useEffect, useReducer, useState } from "react";
import { getCurrentPeriod } from "src/utils/dates";
import CardsFiles from "src/components/cards/CardsFiles";
import { GET_PERIOD_DATA } from "src/services/graphql/queries";
import { useLazyQuery } from "@apollo/client";
import { ModalFiles } from "src/pages/collection/previred/modals/modalFiles";
import {
  Box,
  Typography,
  Divider,
  Button,
  Grid,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import {
  detailText,
  littleDetail,
  littleTitle,
} from "src/components/typography/styles";
import { filterBox } from "src/components/box/styles";
import Consolid from "src/assets/icons/consolid.svg";
import Upload from "src/assets/icons/uploadWhite.svg";
import LoadPreviredFiles from "src/components/loaderPages/loadPreviredFiles";
import { TabPanel } from "src/components/tabs/tabPanel";
import TablePrevired from "./components/previredTable";
import SearchPrevired from "./components/searchPrevired";
import TableRecordPrevired from "./components/tableRecordPrevired";
import TableDeletePrevired from "./components/tableDeletePrevired";
import Breadcrum from "src/components/breadcrum/Breadcrum";
import { useLocation } from "react-router-dom";
import DownloadPrevired from "./components/downloadPrevired";
import { hasPermissions } from "src/utils/hasPermissions";
import { AlertSnackbar } from "src/components";
import {
  SNACK_ACTION_TYPE,
  SNACKINFO_INITIAL_STATE,
  snackInfoReducer,
} from "src/reducers/snackInfoReducer";

const { CLOSE_SNACK_INFO } = SNACK_ACTION_TYPE;

const Previred = () => {
  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const [files, setFiles] = useState(null);
  const [done, setDone] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [count, setCount] = useState(0);
  const [anyCardFull, setAnyCardFull] = useState(false);
  const [valorTab, setValorTab] = useState(0);
  const location = useLocation();
  const period = getCurrentPeriod(location.state?.period);
  const [snackInfoState, snackInfoDispatch] = useReducer(
    snackInfoReducer,
    SNACKINFO_INITIAL_STATE
  );

  const [getData, { data, loading: loadingData }] = useLazyQuery(
    GET_PERIOD_DATA,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    getData({
      variables: {
        input: {
          targetYear: parseInt(period[2]),
          targetMonth: parseInt(period[1]),
        },
      },
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (refresh === true) {
      getData({
        variables: {
          input: {
            targetYear: parseInt(period[2]),
            targetMonth: parseInt(period[1]),
          },
        },
      });
    }
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    const totalFiles =
      (data?.period?.collection?.previred?.cards?.pl?.filesCount || 0) +
      (data?.period?.collection?.previred?.cards?.aa?.filesCount || 0);
    setCount(totalFiles);
    setAnyCardFull(
      Boolean(
        data?.period?.collection?.previred?.cards?.pl?.amount ||
          data?.period?.collection?.previred?.cards?.aa?.amount
      )
    );
    // eslint-disable-next-line
  }, [data]);

  return (
    <>
      <AlertSnackbar
        onClose={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        open={snackInfoState.open}
        status={snackInfoState.status}
        message={snackInfoState.message}
      />
      <SearchPrevired onOpen={false} />
      <ModalFiles
        modal={openUpload}
        setModal={setOpenUpload}
        files={files}
        setFiles={setFiles}
        period={period}
        maxFiles={10}
        done={done}
        setDone={setDone}
        setRefresh={setRefresh}
      />
      <Box pl="32px" pr="32px">
        <Typography variant="h1" pt="24px" mb="16px">
          Archivos Previred - {period[0]}
        </Typography>
        <Breadcrum
          back1="Inicio"
          link1="/"
          now={"Previred " + period[0]}
          state={{ period: period }}
        />
        {loadingData ? (
          <LoadPreviredFiles />
        ) : (
          <>
            <Box sx={filterBox} mt="16px" mb="24px">
              <Grid container>
                <Grid xs={7}>
                  <Typography sx={littleTitle} component="div">
                    Consolidación previred
                  </Typography>
                  <Typography
                    sx={detailText}
                    component="div"
                    pt="16px"
                    pb="8px   "
                  >
                    Aquí puedes cargar los archivos del periodo actual para
                    descargar un consolidado.
                  </Typography>
                  {/* <Typography sx={subText20} component="div">
                    No hay cargas registradas
                  </Typography> */}
                </Grid>
                <Grid xs={5} pt="36px">
                  <Stack
                    direction="row"
                    textAlign="right"
                    justifyContent="flex-end"
                  >
                    <Typography sx={littleDetail} component="div" pr="16px">
                      Formato aceptados: .txt <br></br>
                      Peso máximo de 4MB
                    </Typography>
                    <Button
                      color="primary"
                      startIcon={<img src={Upload} alt="startIcon" />}
                      onClick={() => setOpenUpload(true)}
                      disabled={!hasPermissions().uploadFile}
                    >
                      Cargar archivo
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
              <Divider component="div" sx={{ mt: "24px" }} />
              <Grid container mt="24px">
                <Grid xs={9}>
                  <img src={Consolid} alt="Icono" />
                  <Typography
                    variant="body1"
                    component="div"
                    fontSize="18px"
                    display="inline"
                    paddingLeft="12px"
                  >
                    Consolidación {period[0]}
                  </Typography>
                </Grid>
                <Grid xs={3} textAlign="right">
                  <Typography variant="body1" component="div" fontSize="18px">
                    Archivos cargados ({count})
                  </Typography>
                </Grid>
                <Grid xs={12} pt="19px">
                  <Stack direction="row" spacing="24px" justifyContent="center">
                    <CardsFiles
                      cardType={"PL"}
                      fileData={data?.period?.collection?.previred?.cards?.pl}
                      period={period[3]}
                      showButton={true}
                    />
                    <CardsFiles
                      cardType={"AA"}
                      fileData={data?.period?.collection?.previred?.cards?.aa}
                      period={period[3]}
                      showButton={true}
                    />
                  </Stack>
                </Grid>
                <Grid xs={12} pt="24px" textAlign="right">
                  <DownloadPrevired
                    period={period[3]}
                    disabled={!anyCardFull}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
        <Tabs
          sx={{ width: "80%" }}
          textColor="primary"
          value={valorTab}
          onChange={(event, value) => setValorTab(value)}
        >
          <Tab label="Previred" />
          <Tab label="Historial de Carga" disabled />
          <Tab label="Registros eliminados" disabled />
        </Tabs>
        <TabPanel value={valorTab} index={0}>
          <TablePrevired
            refresh={refresh}
            setRefresh={setRefresh}
            setSnackInfo={snackInfoDispatch}
            period={period[3]}
            canUpdate={hasPermissions().update}
          />
        </TabPanel>
        <TabPanel value={valorTab} index={1}>
          <TableRecordPrevired />
        </TabPanel>
        <TabPanel value={valorTab} index={2}>
          <TableDeletePrevired />
        </TabPanel>
      </Box>
    </>
  );
};

export default Previred;
