import { Button } from "@mui/material";
import GreenDownload from "src/assets/icons/greenDownload.svg";
import download from "src/assets/icons/download.svg";
import { useLazyQuery } from "@apollo/client";
import { PREVIRED } from "src/services/graphql/queries";
import { previredConsolidated } from "src/utils/buildExcel";
import { useReducer, useState } from "react";
import constants from "src/constants/constants";
import {
  SNACK_ACTION_TYPE,
  SNACKINFO_INITIAL_STATE,
  snackInfoReducer,
} from "src/reducers/snackInfoReducer";
import { AlertSnackbar } from "src/components";

const { CLOSE_SNACK_INFO, SET_SNACK_ERROR, SET_SNACK_INFO } = SNACK_ACTION_TYPE;
const {
  COMPONENTS: {
    BUTTONS: { DOWNLOAD },
  },
  SNACKBAR_MESSAGE: { PREVIRED_CONSOLIDATED_ERROR },
} = constants;

const DownloadPrevired = (props: any) => {
  const [inProgress, setInProgress] = useState(false);
  const [snackInfoState, snackInfoDispatch] = useReducer(
    snackInfoReducer,
    SNACKINFO_INITIAL_STATE
  );

  const [getPreviredData, { fetchMore }] = useLazyQuery(PREVIRED, {
    fetchPolicy: "no-cache",
    onCompleted: (res) => fetchPreviredData(res),
    onError: () => {
      snackInfoDispatch({
        type: SET_SNACK_ERROR,
        payload: PREVIRED_CONSOLIDATED_ERROR,
      });
      setInProgress(false);
    },
  });

  const fetchPreviredData = async (result: any) => {
    let tempPrevired = result.period.collection.previred.previredAA.allBy;
    let tempPayment = result.period.collection.payment.allBy;
    if (tempPrevired && tempPayment) {
      while (
        tempPrevired.rows.length < tempPrevired.count ||
        tempPayment.rows.length < tempPayment.count
      ) {
        const { data } = await fetchMore({
          variables: {
            input: {
              collectionPeriod: props.period,
              isActive: true,
              limit: 2500,
              offset: tempPrevired.rows.length,
            },
            allByInput2: {
              collectionPeriod: props.period,
              paymentType: "PREVIRED",
              isActive: true,
              limit: 2500,
              offset: tempPayment.rows.length,
            },
          },
        });
        const mergePreviredRows = [
          ...tempPrevired.rows,
          ...data.period.collection.previred.previredAA.allBy.rows,
        ];
        const mergePaymentRows = [
          ...tempPayment.rows,
          ...data.period.collection.payment.allBy.rows,
        ];
        tempPrevired = { ...tempPrevired, rows: mergePreviredRows };
        tempPayment = { ...tempPayment, rows: mergePaymentRows };
      }
      downloadPreviredFile(tempPayment.rows, tempPrevired.rows);
    }
  };

  const downloadPreviredFile = async (dataPL: any, dataAA: any) => {
    let response = await previredConsolidated(props.period, dataPL, dataAA);
    if (response.status !== undefined) {
      snackInfoDispatch({
        type: SET_SNACK_INFO,
        payload: {
          open: true,
          message: response.message,
          status: response.status,
        },
      });
    } else {
      snackInfoDispatch({
        type: SET_SNACK_ERROR,
        payload: PREVIRED_CONSOLIDATED_ERROR,
      });
    }
    setInProgress(false);
  };

  const handleDownload = () => {
    setInProgress(true);
    getPreviredData({
      variables: {
        input: {
          collectionPeriod: props.period,
          isActive: true,
          limit: 2500,
          offset: 0,
        },
        allByInput2: {
          collectionPeriod: props.period,
          isActive: true,
          paymentType: "PREVIRED",
          limit: 2500,
          offset: 0,
        },
      },
    });
  };

  return (
    <>
      <AlertSnackbar        
        onClose={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        open={snackInfoState.open}
        status={snackInfoState.status}
        message={snackInfoState.message}
      />
      {inProgress ? (
        <Button color="primary" sx={{ height: "50px", width: "140px" }}>
          <div className="spinnerButton"></div>
        </Button>
      ) : (
        <Button
          color="secondary"
          startIcon={
            props.disabled ? (
              <img src={download} alt="Icono" />
            ) : (
              <img src={GreenDownload} alt="Icono" />
            )
          }
          disabled={props.disabled}
          onClick={() => handleDownload()}
        >
          {DOWNLOAD}
        </Button>
      )}
    </>
  );
};

export default DownloadPrevired;
