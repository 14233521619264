import { useReducer } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_FILE_GD } from "src/services/graphql/queries";
import { Button } from "@mui/material";
import { base64ToFile, base64ToExcelFile } from "src/utils/files";
import download from "src/assets/icons/download.svg";
import constants from "src/constants/constants";
import {
  SNACK_ACTION_TYPE,
  SNACKINFO_INITIAL_STATE,
  snackInfoReducer,
} from "src/reducers/snackInfoReducer";
import AlertSnackbar from "../snackbar/AlertSnackbar";

const {
  SNACKBAR_MESSAGE: { DOWNLOAD_ERROR, DOWNLOAD_SUCCESS },
} = constants;
const { CLOSE_SNACK_INFO, SET_SNACK_ERROR, SET_SNACK_SUCCESS } =
  SNACK_ACTION_TYPE;

const DownloadButton = ({
  documentId,
  disabled,
  display,
  fileType,
  txt,
}: {
  documentId: string;
  disabled: boolean;
  display: string;
  fileType: string;
  txt: boolean;
}) => {
  const [snackInfoState, snackInfoDispatch] = useReducer(
    snackInfoReducer,
    SNACKINFO_INITIAL_STATE
  );

  const [getDataGd, { loading }] = useLazyQuery(GET_FILE_GD, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      snackInfoDispatch({
        type: SET_SNACK_SUCCESS,
        payload: DOWNLOAD_SUCCESS,
      });
    },
    onError: () => {
      snackInfoDispatch({
        type: SET_SNACK_ERROR,
        payload: DOWNLOAD_ERROR,
      });
    },
  });

  const getFileData = async (id: string) => {
    try {
      await getDataGd({
        variables: {
          input: {
            documentId: id,
          },
        },
      }).then((res) => {
        if (res.data?.period?.collection.previred !== undefined) {
          if (fileType === "txt") {
            base64ToFile(
              res.data.period.collection.previred.documentGD.file,
              res.data.period.collection.previred.documentGD.name
            );
          }
          if (fileType === "xlsx") {
            base64ToExcelFile(
              res.data.period.collection.previred.documentGD.file,
              res.data.period.collection.previred.documentGD.name
            );
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <AlertSnackbar        
        onClose={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        open={snackInfoState.open}
        status={snackInfoState.status}
        message={snackInfoState.message}
      />
      {loading ? (
        <Button
          color="secondary"
          size="small"
          sx={
            display === "detail"
              ? {
                  pl: "10px !important",
                  pr: "10px !important",
                  minWidth: "146.71px",
                }
              : { minWidth: "170.71px" }
          }
        >
          <div className="spinnerButtonGreen"></div>
        </Button>
      ) : (
        <Button
          sx={
            display === "detail"
              ? { pl: "10px !important", pr: "10px !important" }
              : { minWidth: "170.71px" }
          }
          color="secondary"
          size="small"
          startIcon={
            disabled ? (
              <img src={download} alt="Icono" />
            ) : (
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="#01736C"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.7057 7.14652L7.3099 12.4465C7.13854 12.6144 6.86146 12.6144 6.6901 12.4465L1.29427 7.14652C1.12292 6.97866 1.12292 6.70723 1.29427 6.53938L2.00885 5.83938C2.18385 5.66795 2.46458 5.67152 2.63229 5.84652L6.04844 9.31438V0.428663C6.04844 0.192949 6.24531 9.15527e-05 6.48594 9.15527e-05H7.50677C7.7474 9.15527e-05 7.94427 0.192949 7.94427 0.428663V9.31438L11.3604 5.84652C11.5318 5.67509 11.8125 5.67152 11.9839 5.83938L12.6984 6.53938C12.8771 6.70723 12.8771 6.97866 12.7057 7.14652ZM13.5625 14.1429H0.4375C0.196875 14.1429 0 14.3358 0 14.5715V15.5715C0 15.8072 0.196875 16.0001 0.4375 16.0001H13.5625C13.8031 16.0001 14 15.8072 14 15.5715V14.5715C14 14.3358 13.8031 14.1429 13.5625 14.1429Z"
                  fill="#01736C"
                />
              </svg>
            )
          }
          onClick={() => getFileData(documentId)}
          disabled={disabled}
        >
          {txt ? "Descargar txt" : "Descargar"}
        </Button>
      )}
    </>
  );
};

export default DownloadButton;
